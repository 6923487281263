import React, { useState,useEffect,useCallback} from 'react';
import { LocalizationProvider, DatePicker} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Modal} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import NoImage from "../../assets/images/Thumbnail.jpg"
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { config } from '../../config';
import { useNavigate } from 'react-router-dom';
import {debounce} from 'lodash';
import {
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  CircularProgress,
  TablePagination,
  Grid,
  Paper,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  Box,  
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  MenuItem,
  Avatar,
  FormHelperText,
  Input,
  InputAdornment,
  OutlinedInput 

} from '@mui/material';
import {
  Menu as MenuIcon,
  Add as AddIcon,
  Assignment as AssignmentIcon,
  PeopleAlt as PeopleAltIcon,
  Search as SearchIcon,
  TrendingUp as TrendingUpIcon,
  Logout as LogoutIcon,
  Edit as EditIcon,
  MoreVert as MoreVertIcon,
  Delete as DeleteIcon,
  Refresh as RefreshIcon,
  TrendingDown
} from '@mui/icons-material';
import axios from 'axios';

 

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState({});
  const [loading, setLoading] = useState(false);
  const[showVendor,setshowVendor] = useState(false);
  const[showExecutive,setshowExecutive] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [openPopup1, setOpenPopup1] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [error, setError] = useState(null);
  const[showOrderDetails,setshowOrderDetails] = useState(false);
  const[orderDetails,setOrderDetails] = useState([]);
  const [agent, setAgent] = useState(selectedItem.agent || '');
  const [imei, setImei] = useState(selectedItem.imei || '');
  const [finalPrice, setFinalPrice] = useState(selectedItem.finalPrice || '');
  const [finalPickupDate, setFinalPickupDate] = useState(selectedItem.finalPickupDate || null);
  const [alternatePhoneNumber, setAlternatePhoneNumber] = useState(selectedItem.alternatePhoneNumber || '');
  const [comment,setComment] = useState('');
  const [idProofDocuments, setIdProofDocuments] = useState({
    aadharFront: selectedItem.idProofDocuments?.aadharFront || null,
    aadharBack: selectedItem.idProofDocuments?.aadharBack || null,
    phoneFront: selectedItem.idProofDocuments?.phoneFront || null,
    phoneBack: selectedItem.idProofDocuments?.phoneBack || null,
    paymentScreenshot: selectedItem.idProofDocuments?.paymentScreenshot || null
  });
const [currentImage, setCurrentImage] = useState(null);
const [editDialogData, seteditDialogData] = useState(false);
const [Proofid,setProofid] = useState(null);
const [completedCount, setCompletedCount] = useState(0);
const [inProgressCount, setInProgressCount] = useState(0);
const [failedCount, setFailedCount] = useState(0);
const [totalCount, setTotalCount] = useState(0);
const[Reason,setReason] = useState(null);
const [file, setFile] = useState(null);
const [vendors, setVendors] = useState([]);
const [vendorDetails, setvendorDetails] = useState([]);
const [executiveDetails, setExecutiveDetails] = useState([]);
const [selectedVendor, setSelectedVendor] = useState({id:"",name:""});
const [selectedExecutive, setSelectedExecutive] = useState({id:"",name:"",vendorId:""});
const[ShowLeads,setShowLeads] = useState(true);
const[ShowVendors,setShowVendors] = useState(false);
const [selectedRows, setSelectedRows] = useState([]);
const [VendorFormData, setVendorFormData] = useState({
  vendorId: "",
  name: "",
  gst: "",
  address: "",
  phoneNumber: "",
});
const [ExecutiveFormData, setExecutiveFormData] = useState({
  ExecutiveId: "",
  name: "",
  address: "",
  phoneNumber: "",
  vendorId:""
});
 const isRowSelected = (row) => selectedRows.some((selectedRow) => selectedRow.ID === row.ID);
 const handleCheckboxChange = (row) => {
   setSelectedRows((prevSelected) =>
    prevSelected.some((selectedRow) => selectedRow.ID === row.ID)
      ? prevSelected.filter((selectedRow) => selectedRow.ID !== row.ID) // Remove if already selected
      : [...prevSelected, row] // Add new selection
  );
};
   // Fetch the list of vendors from the backend
 
    const fetchVendors = async () => {
      try {
        const response = await axios.post(`${config.Api}vendors`);
          setVendors(response?.data);
      } catch (error) {
        console.error("Error fetching vendors:", error);
      }
    };

    const fetchAllExecutives = async () => {
      try {
        const response = await axios.post(`${config.Api}all-executives`);
        const data = response.data;
        //console.log(data,"exeData")
        setExecutiveDetails(data);
      } catch (error) {
        console.error("Error fetching executives:", error);
      }
    };


    const fetchAllVendors = async () => {
      try {
        const response = await axios.post(`${config.Api}all-vendors`);
        const data = response.data;
        //console.log(data,"vendorsdata")
        setvendorDetails(data);
      } catch (error) {
        console.error("Error fetching vendors:", error);
      }
    };

  
 

  const handleImageClick = (imageUrl) => {
    setCurrentImage(imageUrl);
    setOpenPopup1(true);
  };
  
  const handleCloseImage = () => {
    setOpenPopup1(false);
  };


    const fetchProofData = async (id) => {
    
      // If proofid and selectedItem.ID are present, fetch agent details
      try {
        const response = await axios.post(`${config.Api}get-proof-details/${id}`);
        const data = response.data;
        
        if (data.length > 0) {
          // Assuming you want to set the state for the first proof in the array
          const proof = data[0];
           setAgent(proof.agent || '');
          setImei(proof.imei || '');
          setFinalPrice(proof.finalPrice || '');
          setFinalPickupDate(proof.finalPickupDate || null);
          setAlternatePhoneNumber(proof.alternatePhoneNumber || '');
          setComment(proof.comment || '');
          setProofid(proof._id || null);
         seteditDialogData(true);
         setIdProofDocuments({
          aadharFront: proof.aadharFront || null,
          aadharBack: proof.aadharBack || null,
          phoneFront: proof.phoneFront || null,
          phoneBack: proof.phoneBack || null,
          paymentScreenshot: proof.paymentScreenshot || null
        });
        } else {
          alert("No Records Found")
        }
        
      } catch (err) {
        setError('Failed to fetch data');
        clearFields();
      }
    
    
    
  };
  

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };


  useEffect(() => {
    fetchAdminData(page);
    fetchAllDashboardData();
    fetchAllVendors();
  }, []);

  const handleFetchsearchDetails = async (term) => {
    if (!term.trim()) {
      setData(null);
      return;
    }
  
    try {
      setLoading(true);
      const response = await axios.post(`${config.Api}globalsearch`, { uniqueId: term });
      const { records} = response.data; // Correctly access the data
     //console.log(records,"records")
      setData(records);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setData(null);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchData = useCallback(debounce(handleFetchsearchDetails, 500), []);
  useEffect(() => {
  
    debouncedFetchData(searchTerm);
    // Cleanup the debounce on unmount
    return () => debouncedFetchData.cancel();
  }, [searchTerm]);

  useEffect(() => {
    fetchAdminData(page);
  }, [page, rowsPerPage]);


  const handleStatusChange = async (row, newStatus) => {
    const updatedRecord = await updateStatus(row.ID, newStatus);

    if (updatedRecord) {
      // Update the status in the state
      const updatedData = data.map(item =>
        item.ID === row.ID ? { ...item, Status: updatedRecord.Status } : item
      );
      setData(updatedData);
      const completedCount = updatedData.filter(item => item.Status === 'Completed').length;
      const inProgressCount = updatedData.filter(item => item.Status === 'In Progress').length;
      const failedCount = updatedData.filter(item => item.Status === 'Failed').length;
  
      setCompletedCount(completedCount);
      setInProgressCount(inProgressCount);
      setFailedCount(failedCount);
    }
  
   
  };


  const updateStatus = async (uniqueId, newStatus) => {
    const userType = 1;
    setLoading(true)
    try {
      const response = await fetch(`${config.Api}update-status/${uniqueId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: newStatus,userType : userType }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update status');
      }
  
      const updatedRecord = await response.json();
      return updatedRecord;
    } catch (error) {
      alert('Error updating status');
      return null;
    }
    finally{
      setLoading(false);
    }
  };
  
 // Fetch the Admin Data

  const fetchAdminData = async (page) => {
    setLoading(true);

    try {
      const response = await axios.post(`${config.Api}userpickupdetails`, {
        page,
        limit: rowsPerPage,
      });
      const { records} = response.data; // Correctly access the data
     
      setData(records);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };


  const fetchAllDashboardData = async () => {
    setLoading(true);

    try {
      const response = await axios.post(`${config.Api}userpickupdetails/all`, {});
      const { records, totalRecords } = response.data;
  
      const completed = records.filter(item => item.Status === 'Completed').length;
      const inProgress = records.filter(item => item.Status === 'In Progress').length;
      const failed = records.filter(item => item.Status === 'Failed').length;
  
      setCompletedCount(completed);
      setInProgressCount(inProgress);
      setFailedCount(failed);
      setTotalCount(totalRecords);
      setLoading(false)
       
    } catch (error) {
      alert('Error fetching all data');
      setLoading(false)
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = () => {
  fetchAdminData(page);
  fetchAllDashboardData();
  }
  
  

const handleUpdate = () => {
    const updatedData = data.map((row) => (row.id === editData.id ? editData : row));
    setData(updatedData);
    setEditData({});
    setOpenPopup(false);
  };
   

  const handleInputChange = (e, field) => {
    setEditData({ ...editData, [field]: e.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchAdminData(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when rows per page changes
    fetchAdminData(0);
  };
  
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  
  };



  
  const handleLogout = () => {
    localStorage.removeItem('authToken');
    // localStorage.removeItem('role');
    navigate('/admin-login'); // Redirect to login page after logout
};
  const handleMoreDetails = (item) => {
    //console.log(item,"item")
    setSelectedItem(item);
    fetchProofData(item.ID);
    getReason(item.ID);
    setDetailsDialogOpen(true);
  };

  const handleLeadDelete = async (row) => {
    setLoading(true); // Start loading
    try {
      const url = `${config.Api}userpickup-delete/${row?.ID}`;
      const response = await fetch(url, {
        method: "POST",
      });
      if (response.ok) {
        alert('Lead Deleted Successfully!');
      } else {
        alert("Failed to Delete the Lead");
      }
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false); // Stop loading after processing
      fetchAdminData(page);
    }
  };

  const handleVendorDelete = async (row) => {
    ////console.log(row,"row");
    // return false;
    setLoading(true); // Start loading
    try {
      const url = `${config.Api}vendor-delete/${row?.vendorId}`;
      const response = await fetch(url, {
        method: "POST",
      });
      if (response.ok) {
        alert('Vendor Deleted Successfully!');
      } else {
        alert("Failed to Delete the Vendor");
      }
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false); // Stop loading after processing
      fetchAllVendors();
    }
  };

  const handleExecutiveDelete = async (row) => {
    ////console.log(row,"e");
    // return;
    setLoading(true); // Start loading
    try {
      const url = `${config.Api}executive-delete/${row?.ExecutiveId}`;
      const response = await fetch(url, {
        method: "POST",
      });
      if (response.ok) {
        alert('Executive Deleted Successfully!');
      } else {
        alert("Failed to Delete the Executive");
      }
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false); // Stop loading after processing
      fetchAllExecutives();
    }
  };
  

  const handleCloseDetailsDialog = () => {
    setDetailsDialogOpen(false);
    setSelectedItem({});
  };

  const getReason = async (uniqueId) => {
    try {
      const response = await fetch(`${config.Api}get-reason/${uniqueId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch Reason');
      }
  
      const data = await response.json();
      setReason(data.reason);
      return data;
    } catch (error) {
      alert('Error fetching status');
      return null;
    }
  };

  const excludeKeys = ['Answers', 'touch','deviceAnswers', 'screenOriginal', 'warranty',"ScreenOptions", "DefectsOptions", "visibleLines","FunctionalOptions","AccOptions","PaymentOption","Status","status","ImageURL","answers","upiid","alternateNumber"];
  const excludeVendorKeys = ["_id","__v","orderDetails"];



  const renderSection = (title, items) => {
    if (items?.length === 0) return null
    if(title === "Functional Conditions" || title === "Accessories Conditions"){
      return (
        <Grid item xs={6} style={{ marginTop: '16px' }}>
          <Typography variant="h6"><strong>{title}</strong></Typography>
          {items?.map((item, index) => (
            <Grid container key={index} spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body1">{item?.label}</Typography>
              </Grid>
            </Grid>
          ))}
          
        </Grid>
      );
    }
    else {
      return (
        <Grid item xs={6} style={{ marginTop: '16px' }}>
          <Typography variant="h6"><strong>{title}</strong></Typography>
          {items?.map((item, index) => (
            <Grid container key={index} spacing={2}>
              {Object.entries(item)?.map(([key, value], idx) => (
                <React.Fragment key={idx}>
                  <Grid item xs={12}>
                    <Typography variant="body1">{value?.text || value?.label}</Typography>
                  </Grid>
                
                </React.Fragment>
              ))}
            </Grid>
          ))}
        </Grid>
      );
    }

   
  };


 // Function to handle PDF generation
const handleGeneratePdf = async () => {
  setLoading(true);
  try {
      const response = await axios.post(`${config.Api}generate-pdf`, selectedItem);
      if (response.status === 200) {
      }
  } catch (error) {
      setError('Error generating PDF');
  } finally {
      setLoading(false);
  }
};


// Function to handle sending email
const handleSendEmail = async () => {
  setLoading(true);
  try {
      const response = await axios.post(`${config.Api}send-email`, { email: selectedItem.Email });
      if (response.status === 200) {
      }
  } catch (error) {
      setError('Error sending email');
  } finally {
      setLoading(false);
  }
};


const handleFormSubmit = async () => {
  // Check if Proofid and selectedItem.ID are valid
  if (!selectedItem.ID) {
    // Show an error message if selectedItem.ID is not valid
    setError("Selected ID must be valid to save changes.");
    return;
  }

  const formData = new FormData();
  formData.append('ID', selectedItem.ID);
  formData.append('email', selectedItem.Email);
  formData.append('agent', agent);
  formData.append('imei', imei);
  formData.append('finalPrice', finalPrice);
  formData.append('finalPickupDate', finalPickupDate);
  formData.append('alternatePhoneNumber', alternatePhoneNumber);
  formData.append('comment', comment);

  // Append files to formData
  if (idProofDocuments.aadharFront) formData.append('aadharFront', idProofDocuments.aadharFront);
  if (idProofDocuments.aadharBack) formData.append('aadharBack', idProofDocuments.aadharBack);
  if (idProofDocuments.phoneFront) formData.append('phoneFront', idProofDocuments.phoneFront);
  if (idProofDocuments.phoneBack) formData.append('phoneBack', idProofDocuments.phoneBack);
  if (idProofDocuments.paymentScreenshot) formData.append('paymentScreenshot', idProofDocuments.paymentScreenshot);
  ////console.log(idProofDocuments,"idProofDocuments")

  try {
    // Determine the URL and method based on the existence of Proofid
    const url = !Proofid ? `${config.Api}proof-details/${selectedItem.ID}` : `${config.Api}proof-details/${selectedItem.ID}/${Proofid}`;
    const method = !Proofid ? 'POST' : 'POST';

    const response = await axios({
      method,
      url,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (response.status === 201) {
      const result = response.data;
      if (!Proofid) setProofid(result._id); // Set Proofid if it's a new record
      alert('Data saved successfully');
      handleCloseDetailsDialog();
    }
    else if(response.status === 200){
      const result = response.data;
      if (!Proofid) setProofid(result._id); // Set Proofid if it's a new record
      alert('Data updated successfully');
      handleCloseDetailsDialog();
    } 
    
    else {
      alert('Failed to save data');
    }
  } catch (error) {
    alert('An error occurred while saving data');
  }
};

const clearFields = () => {
  setProofid(null);
  setAgent('');
  setImei('');
  setFinalPrice('');
  setFinalPickupDate(null);
  setAlternatePhoneNumber('');
  setComment('');
  setIdProofDocuments({
    aadharFront: null,
    aadharBack: null,
    phoneFront: null,
    phoneBack: null,
    paymentScreenshot: null
  });
};

const handleImportFileChange = (e) => {
  setFile(e.target.files[0]);
};


const handleFileChange = (e, field) => {
  const file = e.target.files[0];
  if (file) {
   setIdProofDocuments(prev => ({
      ...prev,
      [field]: file // Store the file object
    }));
  }
};

const toggleEditMode = () => {
  if (Proofid && selectedItem.ID) {
    // Toggle edit mode only if both Proofid and selectedItem.ID are present
    seteditDialogData(!editDialogData);
  } else {
    // If either Proofid or selectedItem.ID is missing, set to save mode
    seteditDialogData(false);
  }
};


// Filter the records by clicking the cards

const filterByStatus = async (status) => {
 const userType = 1;
  try {
    const response = await axios.post(`${config.Api}data?status=${status}&userType=${userType}`);
    ////console.log(response,"response")
    setData(response.data);
  } catch (error) {
  }
};

const handleImportClick = async () => {


  const formData = new FormData();
  formData.append('file', file);

  try {
    setLoading(true);
    const response = await axios.post(`${config.Api}save-sheet`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    alert(response.data);
  } catch (error) {
    alert('Failed to import data');
  } finally {
    setLoading(false);
  }
};



 
 

// Inside your Dialog component
<DialogActions>
    <Button onClick={handleCloseDetailsDialog}>Close</Button>
    {/* <Button onClick={handleInvoiceSubmit} color="primary" variant="contained">Submit</Button> */}
</DialogActions>


const handleVendorSubmit = async () => {
  // Add form validations if needed
  if (!VendorFormData.vendorId || !VendorFormData.name || !VendorFormData.gst) {
    alert("Please fill in all required fields!");
    return;
  }

  try {
    // Call the API with form data
    const response = await fetch(`${config.Api}add-vendor`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(VendorFormData),
    });

    if (!response.ok) {
      throw new Error(`Failed to submit data: ${response.statusText}`);
    }
    alert("Vendor added successfully!");

    // Close the dialog and reset the form
    setshowVendor(false);
    setVendorFormData({
      vendorId: "",
      name: "",
      gst: "",
      address: "",
      phoneNumber: "",
    });
  } catch (error) {
    console.error("Error submitting vendor data:", error);
    alert("An error occurred while submitting the form. Please try again.");
  }
};


const handleExecutiveSubmit = async () => {
  // Add form validations if needed
  if (!ExecutiveFormData.ExecutiveId || !ExecutiveFormData.name || !ExecutiveFormData.vendorId) {
    alert("Please fill in all required fields!");
    return;
  }

  try {
    // Call the API with form data
    const response = await fetch(`${config.Api}add-executive`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(ExecutiveFormData),
    });

    if (!response.ok) {
      throw new Error(`Failed to submit data: ${response.statusText}`);
    }

    
    alert("Executive added successfully!");
    

    // Close the dialog and reset the form
    setshowExecutive(false);
    setExecutiveFormData({
      vendorId: "",
      name: "",
      gst: "",
      address: "",
      phoneNumber: "",
    });
  } catch (error) {
    console.error("Error submitting vendor data:", error);
    alert("An error occurred while submitting the form. Please try again.");
  }
};


const handleVendorChange = (e) => {
  const { name, value } = e.target;
  setVendorFormData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
};

const handleExecutiveChange = (e,type) => {
  if(type === "select"){
    fetchVendors();
  }
  const { name, value } = e.target;
  setExecutiveFormData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
};

const handleAssignClick = async (param) => {
  // Map selected rows to order details
  const orderDetails = selectedRows.map((row) => ({
    orderId: row.ID, // Order ID
    brandName: row["Brand Name"], // Brand Name
    model: row.Model, // Device Model
    variant: row.formattedVariant, // Device Variant
    customerName: row.Name, // Customer Name
    customerEmail: row.Email, // Customer Email
    customerPhone: row.PhoneNumber, // Customer Phone Number
    customerAddress: row.Address, // Customer Address
    pinCode: row.pinCode, // Customer Pin Code
    quotedPrice: row.QuotedPrice, // Quoted Price
    pickupDate: row["Pickup Date"], // Pickup Date
    pickupTime: row.PickupTime, // Pickup Time
    paymentOption: row.PaymentOption, // Payment Option
    answers: row.Answers, // Device Answers
    status: row.Status, // Order Status
  }));

  // Validate selection
  if (selectedRows.length === 0) {
    alert("Please select orders to assign!");
    return;
  }

  if (param === "vendor") {
    if (!selectedVendor.id) {
      alert("Please select a vendor before assigning!");
      return;
    }
    // Call assignOrders for vendor
    await assignOrders(selectedVendor.id, orderDetails, "Vendor");
  } else if (param === "executive") {
    if (!selectedExecutive.id) {
      alert("Please select an executive before assigning!");
      return;
    }
    // Call assignOrders for executive
    await assignOrders(
      selectedExecutive.id,
      orderDetails,
      "Executive",
      selectedExecutive.vendorId // Pass vendorId for the executive
    );
  } else {
    alert("Invalid assignment type!");
    return;
  }
};


const assignOrders = async (id, orderDetails, type, vendorId = null) => {
  try {
    // Dynamically set the payload key based on type
    const payloadKey = type === "Vendor" ? "vendorId" : "ExecutiveId";
    const payload = {
      [payloadKey]: id,
      orderDetails,
      ...(type === "Executive" && vendorId ? { vendorId } : {}), // Include vendorId for executives
    };

    //console.log(payload, "payload");
    // return false;
    // Make API request
    const response = await axios.post(`${config.Api}assign-orders`, payload);

    // Notify success
    alert(response.data.message);
    setSelectedRows([]);
    ////console.log(`Updated ${type} Data:`, response.data.updatedData);
  } catch (error) {
    console.error(`Error assigning orders to ${type}:`, error);
    alert(`Failed to assign orders to ${type}. Please try again.`);
    setSelectedRows([]);

  }
};

const FetchAssignedOrders = async (row,type) => {
  //console.log(row,"WW");
  // setDistributor
  // return;
  setshowOrderDetails(true);
   setLoading(true);
   setError("");
  try {
    const response = await (type === "vendor"
      ? axios.post(`${config.Api}vendor-orders/${row?.vendorId}`)
      : axios.post(`${config.Api}executive-orders/${row?.ExecutiveId}`));    
      setOrderDetails(response.data.orderDetails);
  } catch (err) {
    setError(err.response?.data?.message || "Something went wrong");
  } finally {
    setLoading(false);
  }
}

 
const deleteAssignedOrder = async (row, type) => {
  //console.log(row, "clicked");
  // return false;

  const { vendorId, ExecutiveId, orderId } = row; // Extract necessary values
  if (!vendorId || !orderId) {
    console.error("Invalid data: vendorId or orderId missing");
    return;
  }

  try {
    const response = await axios.post(`${config.Api}delete-assigned-order`, {
      vendorId,
      ExecutiveId: ExecutiveId || null, // Send null if executiveId doesn't exist
      orderId,
    });

   alert("Order deleted successfully:", response.data);
    // Optionally, refresh the order list or show a success message
  } catch (err) {
    console.error("Error deleting order:", err.response?.data?.message || err.message);
  }
};





return (
    <Container maxWidth={false}  sx={{ backgroundColor: '#1c1c1c', minHeight: '100vh', py: 4 }}>
      <>
     <AppBar position="static">
     <Toolbar>
    <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer}>
      <MenuIcon />
    </IconButton>
    <Typography variant="h6" sx={{ flexGrow: 1, fontSize: '1.2rem' }}>
      Admin Dashboard
  <IconButton onClick={handleRefresh} sx={{ ml: 1 }} aria-label="refresh">
    <RefreshIcon style={{color:"white"}}  />
  </IconButton>
  <Button color="secondary" variant="contained" onClick={() => setshowVendor(true)} sx={{ fontSize: '0.7rem' }}>
   <AddIcon fontSize='small'/>  
   Vendor
   </Button>

   <Button color="secondary" variant="contained" onClick={() => setshowExecutive(true)} sx={{ fontSize: '0.7rem' }}>
   <AddIcon fontSize='small'/>  
   Executive
   </Button>
  
 
    </Typography>

        
   
  
    <Box display="flex" alignItems="end" sx={{ mr: 2 }}>
    <Input type="file" onChange={handleImportFileChange} />
      <Button
       startIcon={<ImportExportIcon/>}
        variant="contained"
        color="primary"
        onClick={handleImportClick}
        disabled={loading || !file}
        style={{ marginTop: 20 }}
      >
        {loading ? <CircularProgress size={24} /> : 'Import and Save Sheet'}
      </Button>
      
    </Box>
      <Avatar sx={{ bgcolor: '#ff5722', mr: 1 }}>A</Avatar>
      <Typography variant="body1" sx={{ fontSize: '1rem', mr: 1 }}>
        Hi, Admin
      </Typography>

     
    
    
    <Button color="secondary" variant="contained" onClick={handleLogout} sx={{ fontSize: '0.7rem' }}>
      <LogoutIcon sx={{ ml: 2,gap:2  }} />
      Logout
    </Button>
</Toolbar>
</AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
        <List>
          <ListItem button key="Leads" onClick={() => {setPage(0); setDrawerOpen(false);setShowLeads(true);setShowVendors(false)}}>
            <ListItemIcon><AssignmentIcon /></ListItemIcon>
            <ListItemText primary="Leads" />
          </ListItem>
          <ListItem button key="Leads" onClick={() => {setPage(0); setDrawerOpen(false);setShowLeads(false);setShowVendors(true);fetchAllVendors()}}>
            <ListItemIcon><PeopleAltIcon /></ListItemIcon>
            <ListItemText primary="Vendors" />
          </ListItem>

          <ListItem button key="Executives" onClick={() => {setPage(0); setDrawerOpen(false);setShowLeads(false);setShowVendors(false);fetchAllExecutives();}}>
            <ListItemIcon><PeopleAltIcon /></ListItemIcon>
            <ListItemText primary="Executives" />
          </ListItem>
            
        </List>
      </Drawer>

      <Grid container spacing={3}>

      <Grid item xs={12} sm={6} md={3}>
        <Paper sx={{ p: 2, bgcolor: '#333', color: 'white', textAlign: 'center', position: 'relative',cursor:"pointer" }} onClick={() => fetchAllDashboardData()}>
          <TrendingUpIcon sx={{ fontSize: 40, position: 'absolute', top: 8, left: 8, color: 'blue' }} />
          <Typography variant="h6" gutterBottom>Over All</Typography>
          <Typography variant="h4" gutterBottom>{totalCount}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Paper sx={{ p: 2, bgcolor: '#333', color: 'white', textAlign: 'center', position: 'relative',cursor:"pointer" }} onClick={() => filterByStatus('Completed')}>
          <TrendingUpIcon sx={{ fontSize: 40, position: 'absolute', top: 8, left: 8, color: '#4caf50' }} />
          <Typography variant="h6" gutterBottom>Completed</Typography>
          <Typography variant="h4" gutterBottom>{completedCount}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Paper sx={{ p: 2, bgcolor: '#333', color: 'white', textAlign: 'center', position: 'relative',cursor:"pointer" }} onClick={() => filterByStatus('In Progress')}>
          <TrendingUpIcon sx={{ fontSize: 40, position: 'absolute', top: 8, left: 8, color: '#ffeb3b' }} />
          <Typography variant="h6" gutterBottom>In Progress</Typography>
          <Typography variant="h4" gutterBottom>{inProgressCount}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Paper sx={{ p: 2, bgcolor: '#333', color: 'white', textAlign: 'center', position: 'relative',cursor:"pointer" }} onClick={() => filterByStatus('Failed')}>
          <TrendingDown sx={{ fontSize: 40, position: 'absolute', top: 8, left: 8, color: 'red' }} />
          <Typography variant="h6" gutterBottom>Failed</Typography>
          <Typography variant="h4" gutterBottom>{failedCount}</Typography>
        </Paper>
      </Grid>

    
     
    </Grid>
    <Grid container>
    <Grid item xs={12} sm={6} md={6}>
    <Select
        style={{background:"white",width:"40%"}}
                    name="vendorId"
                    labelId="vendorId-label"
                    id={selectedVendor.id}
                    value={selectedVendor.name}
                    // onChange={(e) => setSelectedVendor(e.target.value)}
                    onChange={(e) => {
                      const selectedVen = vendorDetails.find((vendor) => vendor.name === e.target.value);
                      setSelectedVendor({ id: selectedVen.vendorId, name: selectedVen.name });
                    }}
                    onOpen={fetchAllVendors} // Trigger API call when dropdown is opened
                    label="Vendor"
                    input={
                      <OutlinedInput
                        endAdornment={
                          <InputAdornment position="end">
                              <IconButton onClick={() => {setSelectedVendor({id:"",name:""})}} edge="end">
                              {selectedVendor && <CloseIcon /> } 
                              </IconButton>
                            </InputAdornment>
                         
                        }
                      />
                    }
                  >
                    {vendorDetails.map((vendor) => (
                      <MenuItem key={vendor.vendorId} value={vendor.name}>
                        {vendor.name} ({vendor.vendorId})
                      </MenuItem>
                    ))}
                  </Select>
            <Button
              variant="contained"
              color="primary"
              // disabled={!vendor}
              onClick={() => handleAssignClick("vendor")}
            >
              Assign vendor
            </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
            <Select
            style={{background:"white",width:"40%"}}
                    name="ExecutiveId"
                    labelId="ExecutiveId-label"
                    id={selectedExecutive.id}
                    value={selectedExecutive.name}
                    onChange={(e) => {
                      const selectedExec = executiveDetails.find((exec) => exec.name === e.target.value);
                      ////console.log(selectedExec,"selectedExec");
                      // return;
                      setSelectedExecutive({ id: selectedExec.ExecutiveId, name: selectedExec.name,vendorId:selectedExec.vendorId});
                    }}
                    onOpen={fetchAllExecutives} // Trigger API call when dropdown is opened
                    label="Executive"
                    input={
                      <OutlinedInput
                        endAdornment={
                          
                            <InputAdornment position="end">
                              <IconButton onClick={() => {setSelectedExecutive({id:"",name:""})}} edge="end">
                               {selectedExecutive &&  <CloseIcon /> }
                              </IconButton>
                            </InputAdornment>
                         
                        }
                      />
                    }
                  >
                    {executiveDetails.map((executive) => (
                      <MenuItem key={executive.ExecutiveId} value={executive.name}>
                        {executive.name} ({executive.vendorId})
                      </MenuItem>
                    ))}
                  </Select>
            <Button
              variant="contained"
              color="secondary"
              // disabled={!executive}
              onClick={() => handleAssignClick("executive")}
            >
              Assign Executive
            </Button>
            </Grid>
 
            </Grid>
      <Paper sx={{ mt: 3, p: 2 }} style={{overflow:"scroll"}}>
   
        <>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <TextField
            value={searchTerm}
              fullWidth
              placeholder="Search..."
              variant="outlined"
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: searchTerm && (
                  <InputAdornment position="end">
                    <IconButton  size="small">
                      <CloseIcon onClick={() => {setSearchTerm("")}} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
               size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {selectedRows.length > 0 && (
              <Button
              variant="contained"
              color="primary"
              // disabled={!vendor}
              onClick={() => setSelectedRows([])}
            >
              Clear Selected Orders
            </Button>
            )}
          
          </Grid>
        </Grid>
      
       
       
      
    
      
      
       
       {ShowLeads ?
       
       !data ? ( <CircularProgress sx={{ mt: 2 }} />) : (

        
        <Table sx={{ mt: 2 }}>
        {data && data.length > 0 && (
          <TableHead>
            <TableRow>
            <TableCell>Select</TableCell> 
              {Object.keys(data[0])
                .filter(key => !excludeKeys.includes(key))
                .map(key => (
                  <TableCell key={key}>{key}</TableCell>
                ))}
             <TableCell>Status</TableCell>
             <TableCell>Actions</TableCell>

            </TableRow>
          </TableHead>
        )}
        <TableBody>
       
          {data
            .filter((row) =>
              Object.values(row).some(
                (value) =>
                  typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
              )
            ).map((row, index) => (
              <TableRow key={row.id}>
                {/* Check box cells */}
                 <TableCell>
                <Checkbox
                  checked={isRowSelected(row)}
                  onChange={() => handleCheckboxChange(row)}
                  inputProps={{ 'aria-label': 'Select row' }}
                />
              </TableCell>
                {Object.keys(row)
                  .filter(key => !excludeKeys.includes(key))
                  .map(key => (
                    <TableCell key={key}>{typeof row[key] === 'object' ? JSON.stringify(row[key]) : row[key]}</TableCell>
                  ))}
                     <TableCell>
                      <Select
                        value={row.Status}
                        onChange={(e) => handleStatusChange(row, e.target.value)}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Status' }}
                      >
                        <MenuItem value="Requested"><em>Requested</em></MenuItem>
                        <MenuItem value="Completed">Completed</MenuItem>
                        <MenuItem value="Failed">Failed</MenuItem>
                        <MenuItem value="In Progress">In Progress</MenuItem>
                      </Select>
                   </TableCell>
                <TableCell style={{display:"flex"}}>
                  <IconButton onClick={() => handleMoreDetails(row)}>
                    <MoreVertIcon />
                    
                  </IconButton>
                  <IconButton onClick={() => handleLeadDelete(row)}>
                    <DeleteIcon />
                    
                  </IconButton>
                 </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

       ) : ShowVendors ? (

        <Table sx={{ mt: 2 }}>
{vendorDetails && vendorDetails.length > 0 && (
  <TableHead>
    <TableRow>
      {Object.keys(vendorDetails[0])
        .filter(key => !excludeVendorKeys.includes(key))
        .map(key => (
          <TableCell key={key}>{key}</TableCell>
        ))}
    

    </TableRow>
  </TableHead>
)}
<TableBody>
  {vendorDetails
    .filter((row) =>
      Object.values(row).some(
        (value) =>
          typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
      )
    ).map((row) => (
      <TableRow key={row.id}>
        {Object.keys(row)
          .filter(key => !excludeVendorKeys.includes(key))
          .map(key => (
            <TableCell key={key}>{typeof row[key] === 'object' ? JSON.stringify(row[key]) : row[key]}</TableCell>
          ))}
            
        <TableCell style={{display:"flex"}}>
          <IconButton onClick={() => {FetchAssignedOrders(row,"vendor")}}>
            <MoreVertIcon />
            
          </IconButton>
          <IconButton onClick={() => handleVendorDelete(row)}>
            <DeleteIcon />
            
          </IconButton>
         </TableCell>
      </TableRow>
    ))}
</TableBody>
</Table>

       ) :
       (
        <Table sx={{ mt: 2 }}>
{executiveDetails && executiveDetails.length > 0 && (
  <TableHead>
    <TableRow>
      {Object.keys(executiveDetails[0])
        .filter(key => !excludeVendorKeys.includes(key))
        .map(key => (
          <TableCell key={key}>{key}</TableCell>
        ))}
     {/* <TableCell>Status</TableCell>
     <TableCell>Actions</TableCell> */}

    </TableRow>
  </TableHead>
)}
<TableBody>
  {executiveDetails
    .filter((row) =>
      Object.values(row).some(
        (value) =>
          typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
      )
    ).map((row, index) => (
      <TableRow key={row.id}>
        {Object.keys(row)
          .filter(key => !excludeVendorKeys.includes(key))
          .map(key => (
            <TableCell key={key}>{typeof row[key] === 'object' ? JSON.stringify(row[key]) : row[key]}</TableCell>
          ))}
            
        <TableCell style={{display:"flex"}}>
          <IconButton onClick={() => FetchAssignedOrders(row,"executive")}>
            <MoreVertIcon />
            
          </IconButton>
          <IconButton onClick={() => handleExecutiveDelete(row)}>
            <DeleteIcon />
            
          </IconButton>
         </TableCell>
      </TableRow>
    ))}
</TableBody>
</Table>
       )
       
       
       }  


       


       
 
        
      
                </>
   


<TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={totalCount} // Use the totalCount state here
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      

</Paper>

{/* Assigned Order Details Table For Respective Vendor and Executive */}
 
<Dialog open={showOrderDetails} onClose={() => setshowOrderDetails(false)}>
      <DialogTitle>Assigned Order Details</DialogTitle>
      <DialogContent>
      <Table sx={{ mt: 2 }}>
        {orderDetails && orderDetails.length > 0 && (
          <TableHead>
            <TableRow>
           {Object.keys(orderDetails[0])
                .filter(key => !excludeKeys.includes(key))
                .map(key => (
                  <TableCell key={key}>{key}</TableCell>
                ))}
           
           
            </TableRow>
          </TableHead>
        )}
        <TableBody>
       
          {orderDetails
            .filter((row) =>
              Object.values(row).some(
                (value) =>
                  typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
              )
            ).map((row) => (
              <TableRow key={row.id}>
               {Object.keys(row)
                  .filter(key => !excludeKeys.includes(key))
                  .map(key => (
                    <TableCell key={key}>{typeof row[key] === 'object' ? JSON.stringify(row[key]) : row[key]}</TableCell>
                  ))}
                    
                    <TableCell style={{display:"flex"}}>
         
          <IconButton onClick={() => deleteAssignedOrder(row)}>
            <DeleteIcon />
            
          </IconButton>
         </TableCell>
              </TableRow>
            ))}
           
        </TableBody>
      </Table>
      </DialogContent>
    
    </Dialog>


<Dialog open={showVendor} onClose={() => setshowVendor(false)}>
      <DialogTitle>Add Vendor Details</DialogTitle>
      <DialogContent>
        <TextField
          name="vendorId"
          label="Vendor ID"
          variant="outlined"
          fullWidth
          margin="normal"
          value={VendorFormData.vendorId}
          onChange={handleVendorChange}
          required
        />
        <TextField
          name="name"
          label="Name (as per GST)"
          variant="outlined"
          fullWidth
          margin="normal"
          value={VendorFormData.name}
          onChange={handleVendorChange}
          required
        />
        <TextField
          name="gst"
          label="GST"
          variant="outlined"
          fullWidth
          margin="normal"
          value={VendorFormData.gst}
          onChange={handleVendorChange}
          required
        />
        <TextField
          name="address"
          label="Address"
          variant="outlined"
          fullWidth
          margin="normal"
          value={VendorFormData.address}
          onChange={handleVendorChange}
        />
        <TextField
          name="phoneNumber"
          label="Phone Number"
          variant="outlined"
          fullWidth
          margin="normal"
          value={VendorFormData.phoneNumber}
          onChange={handleVendorChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setshowVendor(false)} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleVendorSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>

    <Dialog open={showExecutive} onClose={() => setshowExecutive(false)}>
      <DialogTitle>Add Executive Details</DialogTitle>
      <DialogContent>
        <TextField
          name="ExecutiveId"
          label="Executive ID"
          variant="outlined"
          fullWidth
          margin="normal"
          value={ExecutiveFormData.ExecutiveId}
          onChange={handleExecutiveChange}
          required
        />
        <TextField
          name="name"
          label="Executive Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={ExecutiveFormData.name}
          onChange={handleExecutiveChange}
          required
        />

<FormControl variant="outlined" fullWidth margin="normal" required>
          <InputLabel id="vendorId-label">Vendor</InputLabel>
          <Select
            name="vendorId"
            labelId="vendorId-label"
            value={ExecutiveFormData.vendorId}
            onChange={(e) => handleExecutiveChange(e, "select")}
    onOpen={fetchVendors} // Trigger API call when dropdown is opened
            label="Vendor"
          >
            {vendors.map((vendor) => (
              <MenuItem key={vendor.vendorId} value={vendor.vendorId}>
                {vendor.name} ({vendor.vendorId})
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      
        <TextField
          name="address"
          label="Address"
          variant="outlined"
          fullWidth
          margin="normal"
          value={ExecutiveFormData.address}
          onChange={handleExecutiveChange}
        />
        <TextField
          name="phoneNumber"
          label="Phone Number"
          variant="outlined"
          fullWidth
          margin="normal"
          value={ExecutiveFormData.phoneNumber}
          onChange={handleExecutiveChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setshowExecutive(false)} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleExecutiveSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>


      <Dialog open={detailsDialogOpen} onClose={handleCloseDetailsDialog} fullWidth maxWidth="md">
      {Reason && (<p style={{color:"red",justifyContent:"center",alignItems:"center",fontSize:"14px",display:"flex"}}>Reason For Cancellation : {Reason}</p>)}
<DialogTitle>Invoice Details</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1"><strong>ID:</strong> {selectedItem["ID"]}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1"><strong>Brand and Model:</strong> {`${selectedItem["Brand Name"]} ${selectedItem.Model} (${selectedItem["formattedVariant"]})`}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1"><strong>Device Condition:</strong></Typography>
            <Typography variant="subtitle1"><strong>Can you able to make and receive calls?:</strong> {selectedItem?.Answers?.calls}</Typography>
            <Typography variant="subtitle1"><strong>Touch screen working properly on your mobile?:</strong> {selectedItem?.Answers?.touch}</Typography>
            <Typography variant="subtitle1"><strong>The display on your phone is it original?:</strong> {selectedItem?.Answers?.screenOriginal}</Typography>
            <Typography variant="subtitle1"><strong>Do You Have a Brand Warranty On Your Phone?:</strong> {selectedItem?.Answers?.warranty}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1"><strong>Estimated Price:</strong> {selectedItem.QuotedPrice}</Typography>
          </Grid>
          {selectedItem.ScreenOptions && renderSection('Screen Conditions', selectedItem.ScreenOptions)}
          {selectedItem.DefectsOptions && renderSection('Defects Conditions', selectedItem.DefectsOptions)}
          {selectedItem.FunctionalOptions && renderSection('Functional Conditions', selectedItem.FunctionalOptions[0]?.functionalProblems)}
          {selectedItem.AccOptions && renderSection('Accessories Conditions', selectedItem.AccOptions)}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1"><strong>Pick up Date:</strong> {selectedItem["Pickup Date"]}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1"><strong>Name:</strong> {selectedItem["Name"]}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1"><strong>Email:</strong> {selectedItem["Email"]}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1"><strong>Phone No:</strong> {selectedItem["PhoneNumber"]}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1"><strong>Phone No:</strong> {selectedItem["selectedWarranty"]}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1"><strong>Address:</strong> {selectedItem["Address"]}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1"><strong>PinCode:</strong> {selectedItem["pinCode"]}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1"><strong>Customer Alternate No:</strong> {selectedItem["alternateNumber"]}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1"><strong>upiid or Number:</strong> {selectedItem["upiid"]}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1"><strong>City:</strong> {selectedItem["city"]}</Typography>
        </Grid>
        

        <Grid item xs={6}>
          <Typography variant="subtitle1"><strong>Agent:</strong></Typography>
          <TextField
            label="Agent"
            fullWidth
            value={agent}
            onChange={(e) => setAgent(e.target.value)}
           />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1"><strong>IMEI:</strong></Typography>
          <TextField
            label="IMEI"
            fullWidth
            value={imei}
            onChange={(e) => setImei(e.target.value)}
           />
        </Grid>
          <Grid item xs={6}>
          <Typography variant="subtitle1"><strong>Final Price:</strong></Typography>
          <TextField
            label="Final Price"
            fullWidth
            value={finalPrice}
            onChange={(e) => setFinalPrice(e.target.value)}
             InputProps={{
              endAdornment: (
                <IconButton onClick={toggleEditMode}>
                  <EditIcon />
                </IconButton>
              )
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1"><strong>Final Pickup Date:</strong></Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Final Pickup Date"
              value={finalPickupDate}
              onChange={(date) => setFinalPickupDate(date)}
               renderInput={(params) => <TextField fullWidth {...params} disabled={!editDialogData} />}
            />
          </LocalizationProvider>
          {error?.pickupDate && <FormHelperText error>{error?.pickupDate}</FormHelperText>}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1"><strong>Alternate Phone Number:</strong></Typography>
          <TextField
            label="Alternate Phone Number"
            fullWidth
            value={alternatePhoneNumber}
            onChange={(e) => setAlternatePhoneNumber(e.target.value)}
             
          />
        </Grid>

        <Grid item xs={6}>
        <Typography variant="subtitle1"><strong>ID Proof Documents:</strong></Typography>
        <Typography variant="subtitle1"><strong>Aadhar Image:</strong></Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Box>
            <img
              src={idProofDocuments.aadharFront ||  NoImage}
              alt="Aadhar Front"
              width="50"
              height="50"
              onClick={() => idProofDocuments.aadharFront && handleImageClick(idProofDocuments.aadharFront)}
              style={{ cursor: idProofDocuments.aadharFront ? 'pointer' : 'default', marginBottom: '8px' }}
            />
            <TextField
              type="file"
              onChange={(e) => handleFileChange(e, 'aadharFront')}
              // disabled={!!idProofDocuments.aadharFront} // Disable if an image is present
            />
          </Box>
          <Box>
            <img
              src={idProofDocuments.aadharBack || NoImage}
              alt="Aadhar Back"
              width="50"
              height="50"
              onClick={() => idProofDocuments.aadharBack && handleImageClick(idProofDocuments.aadharBack)}
              style={{ cursor: idProofDocuments.aadharBack ? 'pointer' : 'default', marginBottom: '8px' }}
            />
            <TextField
              type="file"
              onChange={(e) => handleFileChange(e, 'aadharBack')}
              // disabled={!!idProofDocuments.aadharBack} // Disable if an image is present
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle1"><strong>Phone Image:</strong></Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Box>
            <img
              src={idProofDocuments.phoneFront || NoImage}
              alt="Phone Front"
              width="50"
              height="50"
              onClick={() => idProofDocuments.phoneFront && handleImageClick(idProofDocuments.phoneFront)}
              style={{ cursor: idProofDocuments.phoneFront ? 'pointer' : 'default', marginBottom: '8px' }}
            />
            <TextField
              type="file"
              onChange={(e) => handleFileChange(e, 'phoneFront')}
              // disabled={!!idProofDocuments.phoneFront} // Disable if an image is present
            />
          </Box>
          <Box>
            <img
              src={idProofDocuments.phoneBack || NoImage}
              alt="Phone Back"
              width="50"
              height="50"
              onClick={() => idProofDocuments.phoneBack && handleImageClick(idProofDocuments.phoneBack)}
              style={{ cursor: idProofDocuments.phoneBack ? 'pointer' : 'default', marginBottom: '8px' }}
            />
            <TextField
              type="file"
              onChange={(e) => handleFileChange(e, 'phoneBack')}
              // disabled={!!idProofDocuments.phoneBack} // Disable if an image is present
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle1"><strong>Payment Screenshot:</strong></Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Box>
            <img
              src={idProofDocuments.paymentScreenshot || NoImage}
              alt="Payment Screenshot"
              width="50"
              height="50"
              onClick={() => idProofDocuments.paymentScreenshot && handleImageClick(idProofDocuments.paymentScreenshot)}
              style={{ cursor: idProofDocuments.paymentScreenshot ? 'pointer' : 'default', marginBottom: '8px' }}
            />
            <TextField
              type="file"
              onChange={(e) => handleFileChange(e, 'paymentScreenshot')}
              // disabled={!!idProofDocuments.paymentScreenshot} // Disable if an image is present
            />
          </Box>
        </Box>
      </Grid>

        <Grid item xs={6}>
          <Typography variant="subtitle1"><strong>Comment:</strong></Typography>
          <TextField
            label="comment"
            fullWidth
            value={comment}
            onChange={(e) => setComment(e.target.value)}
             
          />
        </Grid>
        <Grid container spacing={2}>
    
    </Grid>


      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDetailsDialog} color="primary" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleFormSubmit} color="primary" disabled={loading}>
        {(Proofid && selectedItem.ID) ? 'Update Proof' : 'Save Proof'}
        </Button>
        <Button onClick={handleGeneratePdf} color="primary" disabled={loading}>
          {loading ? 'Generating PDF...' : 'Generate PDF'}
        </Button>
        <Button onClick={handleSendEmail} color="primary" disabled={loading}>
        {loading ? 'Sending Email...' : 'Send Email'}
    </Button>
        {error && (
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        )}
      </DialogActions>
    </Dialog>

      
      <Dialog open={openPopup} onClose={() => setOpenPopup(false)} fullWidth maxWidth="sm">
        <DialogTitle>Edit Data</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {Object.keys(editData).map((key) => (
              <Grid item xs={12} sm={6} key={key}>
                <TextField
                  fullWidth
                  label={key}
                  variant="outlined"
                  value={editData[key]}
                  onChange={(e) => handleInputChange(e, key)}
                  multiline={typeof editData[key] === 'object'}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPopup(false)}>Cancel</Button>
          <Button onClick={handleUpdate}>Update</Button>
        </DialogActions>
      </Dialog>

      <Modal open={openPopup1} onClose={handleCloseImage}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            bgcolor: 'background.paper',
            p: 4,
            outline: 'none',
            position: 'relative'
          }}
        >
          <IconButton
            onClick={handleCloseImage}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          {currentImage && <img src={currentImage} alt="Popup" style={{ maxHeight: '90%', maxWidth: '90%' }} />}
        </Box>
      </Modal>
      </>
    
    </Container>
  );
};

export default AdminDashboard;
