import React,{useState,useEffect} from "react";
import { Box, Typography, Button, Card, CardContent, IconButton,Dialog, DialogActions, DialogContent, DialogTitle, TextField, } from "@mui/material";
import { ArrowBack} from "@mui/icons-material";
import { useNavigate,useLocation} from 'react-router-dom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { handleupdateAddress } from "../../redux/WrapperSlice";
import AddCircleIcon from '@mui/icons-material/AddCircle'; // Import the icon

 import { config } from '../../config';
const ManageAddress = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const updateAddress1 = useSelector((state) => state.wrapper.updateAddress);
    //console.log(updateAddress1,"updateAddress1")
    const phoneNumber = location.state?.phoneNumber;
    //console.log(phoneNumber,"phoneNumber")

    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const city = useSelector((state) => state.wrapper.city);
     const handleOpenEdit = (param) => {
      if(param === "Add"){
        dispatch(handleupdateAddress(""))
      }
      setOpenEdit(true);
    
    };
    const handleCloseEdit = () => setOpenEdit(false);
    const handleOpenDelete = () => setOpenDelete(true);
    const handleCloseDelete = () => setOpenDelete(false);

    useEffect(() => {
      fetchAddress(); // Fetch address when component initially mounts or phoneNumber changes
    }, [phoneNumber]); 

  // Function to fetch address based on phoneNumber
  const fetchAddress = async () => {
    try {
      const response = await axios.post(`${config.Api}fetch-address`, { phoneNumber });


      //console.log('Fetched Address:', response.data);
     dispatch(handleupdateAddress(response?.data?.address));

    } catch (error) {
      console.error('Error fetching address:', error.response?.data || error.message);
    }
  };
 

    const updateAddress = async () => {
      setOpenEdit(false);
      try {
        const response = await axios.post(`${config.Api}update-address`,{
          phoneNumber,
          address:updateAddress1,
        });
        //console.log(response,"response")

      const response1 = await fetchAddress(phoneNumber); // Fetch the updated address
        //console.log(response1,"response1")
      } catch (error) {
        console.error('Error updating address:', error.response?.data || error.message);
      }
    };
    
// Delete Address API Call with Axios
const deleteAddress = async () => {
  try {
    const response = await axios.post(`${config.Api}delete-address`,{ phoneNumber });  
    //console.log(response,"response")
    dispatch(handleupdateAddress(""));
    setOpenDelete(false);
  } catch (error) {
    console.error('Error deleting address:', error.response?.data || error.message);
  }
};
  
  
  return (
    <Box sx={{ width: "100%", maxWidth: 400, mx: "auto", p: 2 }}>
      
      {/* Header with Back Button */}

     {!updateAddress1 && (
  <Button
  onClick={() => handleOpenEdit("Add")}
  variant="contained"
  sx={{
    backgroundColor: '#1976d2',
    color: 'white',
    borderRadius: '8px',
    textTransform: 'none',
    fontWeight: 'bold',
    padding: '8px 16px',
    display: 'flex',  
    alignItems: 'center',  
    '&:hover': {
      backgroundColor: '#1565c0',
    },
  }}
>
  <AddCircleIcon sx={{ marginRight: '8px' }} />  
  Add New Address
</Button>   
     ) } 
     
      
      <>
<Box display="flex" alignItems="center" mb={2}>
        <IconButton onClick={() => navigate("/profile")}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h6" fontWeight="bold">My Address</Typography>
      </Box>

      <Card sx={{ borderRadius: 3, boxShadow: 1 }}>
      <CardContent>
        <Box display="flex" alignItems="center" mb={1}>
          <LocationOnIcon color="primary" />
          <Typography variant="subtitle1" fontWeight="bold" ml={1}>{city}</Typography>
        </Box>

     {/* <Typography variant="body2">{address.city}</Typography> */}
        <Typography variant="body2">{updateAddress1}</Typography> 

        <Box display="flex" mt={2} gap={2}>
          <Typography variant="body2" color="primary" sx={{ cursor: 'pointer' }} onClick={() => handleOpenEdit("edit")}>Edit</Typography>
          <Typography variant="body2" color="error" sx={{ cursor: 'pointer' }} onClick={handleOpenDelete}>Delete</Typography>
        </Box>

        {/* Edit Dialog */}
        <Dialog open={openEdit} onClose={handleCloseEdit} fullWidth maxWidth="sm">
          <DialogTitle>Edit Address</DialogTitle>
          <DialogContent>
            {/* <TextField
              label="Label"
              fullWidth
              margin="dense"
              value={address.label}
              onChange={(e) => setAddress({ ...address, label: e.target.value })}
            />
            <TextField
              label="City"
              fullWidth
              margin="dense"
              value={address.city}
              onChange={(e) => setAddress({ ...address, city: e.target.value })}
            /> */}
            <TextField
              label="Details"
              fullWidth
              multiline
              rows={3}
              margin="dense"
              value={updateAddress1}
              onChange={(e) => dispatch(handleupdateAddress((e.target.value)))}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEdit} color="inherit">Cancel</Button>
            <Button onClick={() => updateAddress()} variant="contained" color="primary">Update</Button>
          </DialogActions>
        </Dialog>

        {/* Delete Confirmation Dialog */}
        <Dialog open={openDelete} onClose={handleCloseDelete}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this address?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDelete} color="inherit">Cancel</Button>
            <Button onClick={deleteAddress} color="error" variant="contained">Delete</Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
    </>
     

    </Box>
  );
};

export default ManageAddress;
