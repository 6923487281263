import React, { useState,useEffect } from 'react';
import { Card, CardContent, Typography, Button, Grid,  useMediaQuery,Container,Paper,Box} from '@mui/material';
 import Screen from './Screen';
import Defects from './Defects';
import Functional from './Functional';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import image1 from '../../assets/images/1.jpg';
import image2 from '../../assets/images/2.jpg';
import DeviceEvaluation from '../commonForms/DeviceEvaluation';
import { useNavigate, useParams,useLocation} from 'react-router-dom';
import Device from './Device';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import {handlecurrentValue,handleScreenDefects,handleScreenselectedOptions,handleDefectsselectedOptions} from "../../redux/WrapperSlice";

const ScreenDefects = ({ 
    userLogDetails,
    onFormData
}) => {
   const navigate = useNavigate();
     const dispatch = useDispatch();
   
   const { productType,brand,model,variant,step1} = useParams(); // Get productType, brand & model from the URL
   const [showScreen, setShowScreen] = useState(false);
   const [showDefects, setShowDefects] = useState(false);
   const [showFunctional, setShowFunctional] = useState(false);
   const[ScreenDefects,setScreenDefects] = useState(true);
   const[showWarrantyPage,setshowWarrantyPage] = useState(true);
   let currentValue = useSelector((state) => state.wrapper.currentValue);
   let deviceCurrValue = useSelector((state) => state.wrapper.deviceCurrValue);
   const reduxscreenDefectSelectedOptions = useSelector((state) => state.wrapper.screenDefects);
   const [showDevice, setShowDevice] = useState(false); // Initially show the device section
   const rowDetails = useSelector((state) => state.wrapper.rowDetails);
   const answers =  useSelector((state) => state.wrapper.answers);
   console.log(currentValue,answers,"screenDefects")
   const formattedVariant = useSelector((state) => state.wrapper.formattedVariant);
  const selectedModel = useSelector((state) => state.wrapper.selectedModel);
const [selectedOptions, setSelectedOptions] = useState({
    screenCondition: [],
    defectsCondition: [],
  });

  console.log(selectedOptions,"selectedoptions")

  const[DisableDefects,setDisableDefects] = useState(false);

  const warrantyConditions = [
    "touch",
    "screenOriginal",
    "Screen cracked/ glass broken",
    "Average:-major scraches, major dents,panel crackes, loose screen gap",
    "Below Average:-Heavy scraches,Heavy dents and phone bent",
    "Phone Bent",
    "Phone Bent With Panel Crack",
    "Only Panel Broken"
  ];

  useEffect(() => {
    navigate(`/sell/${productType}/${brand}/${model}/${variant}/${step1}/sd/`, { replace: true });
 
}, []);
useEffect(() => {
  setSelectedOptions(reduxscreenDefectSelectedOptions);
  console.log(reduxscreenDefectSelectedOptions,"reduxscreenDefectSelectedOptions");
 
}, [reduxscreenDefectSelectedOptions]);
  
  // const shouldDeductWarranty = answers.warranty === "no" || answers.touch === "no" || answers.screenOriginal === "no" || warrantyConditions.some(condition => Object.values(selectedOptions).map(option => option.text).includes(condition));
 
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleSelection = (categoryId, option) => {
    setSelectedOptions((prevSelectedOptions) => {
      const currentSelected = prevSelectedOptions[categoryId] || [];
      const isSelected = currentSelected.includes(option.id);

      let updatedSelection;

    if (isSelected) {
      // If already selected, remove it (Unselected case)
      updatedSelection = currentSelected.filter((id) => id !== option.id);

      console.log("Unselected option:", option.id); // Handle unselected logic here
      if (!selectedOptions.screenCondition.includes("screenDefect") && 
      !selectedOptions.defectsCondition.includes("bodyDefect")) {
    dispatch(handleScreenselectedOptions({
      screenCondition: null,
      pixelSpots: null,
      visibleLines: null
    }));
  
    dispatch(handleDefectsselectedOptions({
      overallCondition: null,
      bodyDefects: null,
    }));
  
    dispatch(handlecurrentValue(deviceCurrValue));
  }
  
      else if(option?.id === "bodyDefect"){
      dispatch(handleDefectsselectedOptions({
          overallCondition : null,
          bodyDefects: null,
        }))

      dispatch(handlecurrentValue(deviceCurrValue))


      }

      else if(option?.id === "screenDefect"){
        dispatch(handleScreenselectedOptions({
          screenCondition: null,
          pixelSpots: null,
          visibleLines: null
          
        }));
  
        dispatch(handlecurrentValue(deviceCurrValue))
  
  
        }
    } else {
      // If not selected, add it
      updatedSelection = [...currentSelected, option.id];
    }

      const newSelection = {
        ...prevSelectedOptions,
        [categoryId]: updatedSelection,
      };

      // Dispatch updated selection to Redux
      dispatch(handleScreenDefects(newSelection));

      return newSelection;
    });
  };
  
  if(showDevice){
    return <Device />
  }
  
  

  const categories = [
    {
      id: 'screenCondition',
      title: 'Screen and Body Defects',
      options: [
        {
          id: 'screenDefect', // Unique ID for screen defect
          text: 'Screen Defects',
          image: image1,
        },
        {
          id: 'bodyDefect', // Unique ID for body defect
          text: 'Body Defects',
          image: image2,
        },
      ],
    },
  ];
  
  
const handleContinue = () => {
if(answers.warranty === "yes" && answers.touch === "yes" && answers.screenOriginal === "yes" ){
  setshowWarrantyPage(true);
}
    const selectedIds = selectedOptions.screenCondition || [];
   // Check if 'screenDefect' or 'bodyDefect' are selected
    const screenSelected = selectedIds.includes('screenDefect');
    const bodySelected = selectedIds.includes('bodyDefect');
  
      // Track deductions for touch and screen
      let touchDeduction = 0;
      let screenDeduction = 0;
  
    if (screenSelected && bodySelected) {
      setShowScreen(true); // Show screen logic if both are selected
      setScreenDefects(false);
      setDisableDefects(false);
    } else if (screenSelected) {
      setShowScreen(true); // Show screen logic if only screen is selected
      setScreenDefects(false);
      setDisableDefects(true);
    } else if (bodySelected) {
         // Check for touch not working
          if (answers.touch === 'no') {
            touchDeduction = rowDetails['Device Touch not working'];
            currentValue += touchDeduction;
            dispatch(handlecurrentValue(currentValue));
            
          }
        
          // Check for screen not original
          if (answers.screenOriginal === 'no') {
            screenDeduction = rowDetails["phone's screen original"];
            currentValue += screenDeduction;
            dispatch(handlecurrentValue(currentValue));

          }


           // Determine the deduction logic
      if (answers.touch === 'no' && answers.screenOriginal === 'no') {
        // If both conditions are 'no', take the maximum deduction value
        const maxDeduction = Math.min(touchDeduction, screenDeduction);
         currentValue += maxDeduction;
        dispatch(handlecurrentValue(currentValue));

      } 
   


      setShowDefects(true); // Show defects logic if only body defects are selected
      setScreenDefects(false);
      setDisableDefects(false);
    } 

    else if(!screenSelected && !bodySelected){
          // Check for touch not working
        if (answers.touch === 'no') {
          touchDeduction = rowDetails['Device Touch not working'];
          currentValue += touchDeduction;
          dispatch(handlecurrentValue(currentValue));

        }
      
        // Check for screen not original
        if (answers.screenOriginal === 'no') {
          screenDeduction = rowDetails["phone's screen original"];
          currentValue += screenDeduction;
          dispatch(handlecurrentValue(currentValue));

        }


         // Determine the deduction logic
    if (answers.touch === 'no' && answers.screenOriginal === 'no') {
      // If both conditions are 'no', take the maximum deduction value
      const maxDeduction = Math.min(touchDeduction, screenDeduction);
       currentValue += maxDeduction;
       dispatch(handlecurrentValue(currentValue));

    } 
      setShowFunctional(true);
      setScreenDefects(false);
      setShowScreen(false);
      setDisableDefects(true);

    }

  };
  
  
        
        

  const handleFormData = (data) => {
     onFormData(data)};

   
   
  
return (
    <div>
     
      {ScreenDefects &&
      <>
         <Button
              variant="outlined"
              color="primary"
              onClick={()=> {
                setShowDevice(true);
                setScreenDefects(false);
              }}
              className="go-back-button"
      >
      <ArrowBackIcon className="go-back-icon" sx={{ mr: 1 }} />
      <Typography variant="body1" className="go-back-text" sx={{ fontWeight: 'bold' }}>
      Go Back
      </Typography>
      </Button>
      <Grid container spacing={2} sx={{ mt: 4 }}>
        <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={7}>
            {/* <Paper elevation={3} sx={{ padding: 3, borderRadius: 4 }}> */}
                  <Typography
                    variant="h6"
                    className="conditionsheading"
                    sx={{
                      marginBottom: 2,
                      fontWeight: 'bold',
                      color: '#333',
                      textAlign: 'center',
                    }}
                  >
                  Select Screen/Body Defects Conditions of Your Device
                  </Typography>
                  <Box
        sx={{
          // position: isMobile ? 'fixed' : 'static',
          bottom: isMobile ? 0 : 'auto',
          height: isMobile ? 'calc(100vh - 70px)' : 'auto', // Set height for mobile screens
          overflowY: isMobile ? 'auto' : 'initial', // Allow scrolling for mobile
          // paddingBottom: { xs: '80px', md: '0' },
          width: '100%',
          p: 1,
             // Hide scrollbar (cross-browser solution)
      '&::-webkit-scrollbar': {
        display: 'none', // Hide scrollbar for WebKit browsers
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'transparent', // Ensure it's completely transparent
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent', // Hide the scrollbar track
      },
      scrollbarWidth: 'none', // Hide scrollbar for Firefox
    }}
      >
              {categories.map((category) => (
                <Category
               
                  key={category.id}
                  categoryId={category.id}
                  title={category.title}
                  options={category.options}
                  selectedOption={selectedOptions[category.id]}
                  onSelection={handleSelection}
                />
              ))}
                 </Box>
         
  
                 <Box
    sx={{
      position: isMobile ? 'fixed' : 'static',
      bottom: isMobile ? 0 : 'auto',
      left: 0,
      width: '100%',
      p: 1,
      backgroundColor: isMobile ? '#fff' : 'transparent',
      boxShadow: isMobile ? '0px -2px 10px rgba(0, 0, 0, 0.1)' : 'none',
    }}
  >

  
<Button
variant="contained"
sx={{
backgroundColor: '#4BCEBD',
color: 'white',
padding: isMobile ? '8px 16px' : '12px 24px',
fontSize: isMobile ? '0.9rem' : '1.2rem',
fontSize: isMobile ? '0.9rem' : '1.2rem',
fontWeight: 'bold',
borderRadius: '10px',
width: '100%',
'&:hover': {
backgroundColor: '#4BCEBD',
},
}}
onClick={handleContinue}
endIcon={<ArrowForwardIcon />}
>
Continue
</Button>
  
  </Box>
                 
                {/* </Paper> */}
            </Grid>
            {!isMobile && (
            <Grid item xs={12} md={5}>
              <DeviceEvaluation 
              // selectedModel={selectedModel}
              //  deviceAnswers={{ ...answers, ...selectedOptions }}
              //  formattedVariant={formattedVariant}
              //   answers={answers}


                />
            </Grid>
            )}
          </Grid>
    
         </Grid>
         </>
        }








{showScreen && (
 <Screen
 DisableDefects={DisableDefects}
 showWarrantyPage={showWarrantyPage}
 userLogDetails={userLogDetails}
 onFormData={handleFormData}
/>
    )}

{showDefects && (
        
        <Defects 
            ScreenselectedOptions={""}
            deviceAnswers={{ ...answers, ...selectedOptions }}
            userLogDetails={userLogDetails}
            onFormData={handleFormData}
               />
       
    )}

{showFunctional && (
         
         <Functional 
               showWarrantyPage={showWarrantyPage}
                deviceAnswers={{ ...answers, ...selectedOptions }} 
                ScreenselectedOptions={""} 
                DefectsselectedOptions={selectedOptions} 
                userLogDetails={userLogDetails}
                onFormData={handleFormData}

                
                />
       
     )}
   
        
    
     
</div>

    
  );
};

// Updated Category component
const Category = ({ categoryId, title, options, selectedOption, onSelection }) => {
  return (
    <div style={{ marginBottom: '20px' }}>
      <p variant="p" className="conditionssubheading" style={{textAlign:"center",textDecoration:"underline"}}>{title}</p>
      <Grid container spacing={2}>
        {options.map((option) => (
          <Item
            key={option.id} // Use `id` for a unique key
            categoryId={categoryId}
            option={option}
            // Check if the option is present in the selectedOption array using `id`
            isSelected={selectedOption?.includes(option.id)}
            onClick={() => onSelection(categoryId, option)}
          />
        ))}
      </Grid>
    </div>
  );
};





// Updated Item component
const Item = ({ option, isSelected, onClick }) => {
  return (
    <Grid item xs={6} sm={6} md={6}>
      <Box
        onClick={onClick}
        sx={{
          cursor: 'pointer',  
          border: isSelected ? '2px solid #4BCEBD' : '1px solid #ddd',
          borderRadius: '12px',
          overflow: 'hidden',
          textAlign: 'center',
          boxShadow: isSelected ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none',
          transition: 'all 0.3s ease',
          '&:hover': {
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
          },
          height: '200px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <img
          src={option.image}
          alt={option.text}
          style={{
            width: '100%',
            height: '120px',
            objectFit: 'contain',
            borderBottom: '1px solid #ddd',
            padding: '10%',
          }}
        />
        <Typography
          variant="subtitle1"
          sx={{
            padding: '12px',
            fontSize: '10px',
            fontWeight: 'bold',
            color: isSelected ? '#4BCEBD' : '#333',
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {option.text}
        </Typography>
      </Box>
    </Grid>
  );
};





export default ScreenDefects;
