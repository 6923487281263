import React from 'react';
import { Box, Typography, Paper, Link,Card, CardContent,Button, IconButton  } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import LanguageIcon from '@mui/icons-material/Language';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import cosimg from "../../assets/images/cos_outside.jpeg";
import NoImage from "../../assets/images/Thumbnail.jpg";


import { useNavigate } from 'react-router-dom';

const ContactUs = () => {
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate('/');
  };
  return (
    <>
    <IconButton onClick={handleBackClick}>
    <Button className='go-back-button' startIcon={<ArrowBackIcon className='go-back-icon'/>}>
    <Typography variant="body1" className='go-back-text' sx={{ fontWeight: 'bold' }} color="#4BCEBD">
           Go back
          </Typography>
    </Button> 
   </IconButton>
    <Box
      sx={{
        background: 'linear-gradient(to right, #0f4c81, #7c31db)',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '500px',
          height: '450px',
          backgroundColor: 'white',
          borderRadius: '16px',
          textAlign: 'center',
          padding: '20px',
        }}
      >
        <Box
          sx={{
            background: 'white',
            borderRadius: '50%',
            width: '100px',
            height: '100px',
            margin: '0 auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={cosimg ? cosimg : NoImage} // Replace with your logo
            alt="logo"
            style={{ width: '100px', height: '80px' }}
          />
        </Box>
        <Typography variant="h5" component="h2" sx={{ marginTop: '20px' }}>
          CONTAC US:
        </Typography>
        <Typography variant="body1" component="p" sx={{ marginTop: '20px'}}>
          Reach us : 
          <strong>sellatcashonspot.in@gmail.com</strong>
        </Typography>
        <Box sx={{ textAlign: 'left', marginTop: '20px', paddingLeft: '20px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <PhoneIcon />
            <Typography variant="body1" component="p" sx={{ marginLeft: '10px' }}>
              +91 -8296961413
            </Typography>
          </Box>
          {/* <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <LanguageIcon />
            <Link href="https://www.cashonspot.in" target="_blank" rel="noopener">
              www.cashonspot.in
            </Link>
          </Box> */}
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <YouTubeIcon />
            <Typography
            style={{cursor:"pointer"}} 
            variant="body1" 
            component="a" 
            href="https://youtube.com/@cashonspot10?si=NVQ1qzFWL9Eb-rVS" 
            sx={{ marginLeft: '10px', textDecoration: 'none' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            @CashOnspot10
          </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <InstagramIcon />
            <Typography
            style={{cursor:"pointer"}} 
            variant="body1" 
            component="a" 
            href="https://www.instagram.com/cashonspot_official?igsh=MWM2Z2lvMHNsb21scQ==" 
            sx={{ marginLeft: '10px', textDecoration: 'none' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            @Cashonspotofficial
          </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
    </>
  );
};

export default ContactUs;

