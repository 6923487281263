import React, { useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Button
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
 

const MyOrders = () => {
  //console.log("MYORDER")
    const navigate = useNavigate();
    const orders = useSelector((state) => state.wrapper.orderDetails);
    //console.log(orders,"ORRRRR");
    const filteredOrders = orders?.filter((order) => order.ID !== null) || [];

   return (
    <Box sx={{ p: 2, backgroundColor: "#f5f5f5", minHeight: "100vh" }}>
        
      <Typography variant="h6" fontWeight="bold" mb={2}>
      <Button startIcon={<ArrowBackIcon  onClick={() => navigate("/profile")} />}></Button>
      Orders List
      </Typography>



      {filteredOrders?.filter((order) => order["Pickup Date"] !== null).map((order, index) => (
        <Card key={index} sx={{ mb: 2, borderRadius: 2, p: 2 }} onClick={() => navigate("/order-details", { state: { order} })}>
          <Box display="flex" alignItems="center" mb={1}>
            <IconButton color={order.Status === 'Failed' ? 'error' : 'success'}>
            {order.Status === 'Failed' ? <CancelIcon /> : <CheckCircleIcon />}
            </IconButton>
            <Box ml={1}>
              <Typography fontWeight="bold">Pickup {order?.Status}</Typography>
              <Typography variant="body2" color="textSecondary">
              Pickup Date: {order["Pickup Date"]}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" alignItems="center">
            <CardMedia
              component="img"
              sx={{ width: 60, height: 60, borderRadius: 1 }}
              image={order.ImageURL}
              alt={order["Brand Name"]}
            />
            <CardContent sx={{ flexGrow: 1, pl: 2 }}>
              <Typography variant="caption" color="textSecondary">
              Order ID: {order.ID}
              </Typography>
              <Typography fontWeight="bold">
                {order["Brand Name"]} {order.Model}
              </Typography>
              <Typography variant="body2" color="textSecondary">
              {order.formattedVariant}
              </Typography>
            </CardContent>
          </Box>

          <Box mt={2} display="flex" justifyContent="space-between">
            <Typography variant="body2" fontWeight="bold">
              Quoted Price
            </Typography>
            <Typography fontWeight="bold">₹{order.QuotedPrice}</Typography>
          </Box>
        </Card>
      ))}
    </Box>
  );
};

export default MyOrders;
