import React, { useState, useEffect } from 'react';
import { Card, CardContent, Grid, Typography, RadioGroup, FormControlLabel, Radio, Button, CircularProgress, Box } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Device from './Device';
import {config} from "../../config"
import Footer from '../commonForms/Footer';
import FAQ from '../commonForms/FAQ';
import { useDispatch, useSelector } from 'react-redux';
import { handleMount,handleformattedVariant,handleAppbar } from '../../redux/WrapperSlice';
import { useNavigate, useParams,useLocation} from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ProductType from "./ProductType";
import { handleScreenDefects,handleAnswers,handleScreenselectedOptions,handleDefectsselectedOptions,handlefunctionalSelectedOptions,handleaccSelectedOptions,handleselectedWarranty} from '../../redux/WrapperSlice';

const VariantSelectionCard = ({userLogDetails,onFormData}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productType,brand,model,variant} = useParams(); // Get productType, brand & model from the URL
  const [selectedVariant, setSelectedVariant] = useState('');
  const [variants, setVariants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showEvaluation, setShowEvaluation] = useState(false);
  const [showVariant, setShowVariant] = useState(true);
  const[ShowProductType,setShowProductType] = useState(false);
  const [maxValue, setMaxValue] = useState(null);
  const selectedModel = useSelector((state) => state.wrapper.selectedModel);
  const city = useSelector((state) => state.wrapper.city);

useEffect(() => {
    // Example API endpoint
    const fetchVariants = async () => {
      setLoading(true);
      try {
        // Construct payload with brand and model (and optionally series)
        const payload = {
          // productCode : selectedModel["Product Code"],
          brand: selectedModel["Brand name"],
          model: selectedModel.Model,
          series: selectedModel.Series, // Optional, handle accordingly
          city : city
        };
        
        // Replace with your actual API endpoint and method
        const response = await fetch(`${config.Api}variants`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch variants');
        }

        const data = await response.json();
        // Assuming data structure contains variants and max values
        setVariants(data.variants);
      } catch (error) {
        return;
       } finally {
        setLoading(false);
      }
    };

    if (selectedModel) {
      fetchVariants();
    }
  }, [selectedModel]);

   useEffect(() => {
       dispatch(handleAnswers({
        calls: '',
        touch: '',
        screenOriginal: '',
        warranty: '',
       }))
       dispatch(handleScreenselectedOptions({
          screenCondition: null,
          pixelSpots: null,
          visibleLines: null
        }))
    
        dispatch(handleDefectsselectedOptions({
          overallCondition : null,
          bodyDefects: null,
      
        }))

           dispatch(handleScreenDefects({
                screenCondition: [],
                defectsCondition: [],
              }))
    
        dispatch(handlefunctionalSelectedOptions({ "functionalProblems":[]}));
        dispatch(handleaccSelectedOptions([]));
        dispatch(handleselectedWarranty(""))
    
    
      },[])

  const handleGetExactValueClick = async () => {
     const variant = variants.find(v => v.variant === selectedVariant);
    if (variant) {
      setMaxValue(variant.maxValue);

      try {
        const payload = {
          productCode : selectedModel["Product Code"],
          brand: selectedModel["Brand name"],
          variant: selectedVariant,
          series: selectedModel.Series,
          model: selectedModel.Model,
          maxValue: variant.maxValue
        };

        const response = await fetch(`${config.Api}getEntireRowDetails`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          if (response.status === 404) {
            alert('No Records Found for this Model');
             return false;
          } else {
            throw new Error('Failed to fetch row details');
          }
        } else {
          const data = await response.json();
          ////console.log("object",data)
          dispatch(handleMount(data));
          setShowVariant(false);
          setShowEvaluation(true);
         
        }
      } catch (error) {
        return;
         
      }
    }
   
    
  
  };

  const handleVariantChange = (event) => {
    const selected = event.target.value;
    setSelectedVariant(selected);
     if (productType && brand && model) {
      const formattedVariant = selected.replace(/\//g, "-");
      navigate(`/sell/${productType}/${brand}/${model}/${formattedVariant}`, { replace: true });
    }
     const variant = variants.find(v => v.variant === selected);
    if (variant) {
      setMaxValue(variant.maxValue);
      dispatch(handleformattedVariant(formatVariant(selected)))


    } else {
      setMaxValue(null); // Reset maxValue if no variant is found
      dispatch(handleformattedVariant(""));
    }
  };

  const handleFormData = (data) => {
     onFormData(data);
  
  };


  if (showEvaluation) {
    return <Device  
   userLogDetails={userLogDetails}
    onFormData={handleFormData}
      />;
  }

  const formatVariant = (variant) => {
    const parts = variant.split('/');
    if (parts.length === 2) {
      return `${parts[0]}GB/${parts[1]}GB`;
    }
    return variant;
  };

  const handleBackClick = () => {
    setShowVariant(false);
    setShowProductType(true);
  
  }

  if(ShowProductType){
    dispatch(handleAppbar(false))
    return <ProductType/>
  }


 
   

  return (
    <div>
      {showVariant && (
        <>
        <Button
        variant="outlined"
        color="primary"
        onClick={handleBackClick}
        className="go-back-button"
>
<ArrowBackIcon className="go-back-icon" sx={{ mr: 1 }} />
<Typography variant="body1" className="go-back-text" sx={{ fontWeight: 'bold' }}>
Go Back
</Typography>
</Button>
          <Grid container spacing={3}>
          {/* Left Side: Mobile Image */}
       
          <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' }, alignItems: 'center' }}>
          <Box sx={{ width: 120, height: 120, backgroundColor: '#f8f8f8', borderRadius: '10px', overflow: 'hidden' }}>
                  <img src={selectedModel[" Image URL"]} alt="Mobile" style={{ width: '100%', height: '100%', objectFit: 'contain',padding:"10%" }} />
                </Box>              
                </Grid>

              {/* Right Side: Details and Variant Selection */}
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Typography variant="h5" fontWeight="bold" sx={{ mb: 1, fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
                  {`${selectedModel["Brand name"]} ${selectedModel["Model"]}`}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  sx={{
                    mb: 1,
                    fontWeight: 'bold',
                    '& span': {
                      color: 'red',
                    },
                  }}
                >
  {selectedVariant && `(${formatVariant(selectedVariant)})`}{' '}
 
</Typography>
<Typography   variant="subtitle1"
                  sx={{
                    mb: 1,
                    fontWeight: 'bold',
                     color:'red',
                    //  fontSize:"large",
                     fontSize: '2rem',
                  }}>
 {/* Price: ₹{maxValue || 'N/A'}  */}
 Price: {maxValue ? `₹ ${new Intl.NumberFormat('en-IN').format(maxValue)}`: "N/A"} 
 </Typography>
                <Typography variant="body1" gutterBottom sx={{ mb: 2, fontSize: { xs: '0.9rem', sm: '1rem' } }}>Choose Variant:</Typography>
                <RadioGroup value={selectedVariant} onChange={handleVariantChange} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                  {loading ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    variants.map((variant, index) => (
                      <>
                      <Grid item xs={6} sm={6} md={4} lg={4}>
                      <FormControlLabel
                        key={index}
                        value={variant.variant}
                        className='varinat'
                        
                        control={ <Radio
                          sx={{
                            color: "#4BCEBD", // Default color
                            "&.Mui-checked": {
                              color: "#4BCEBD", // Checked color
                            },
                          }}
                        />
                      }
                        label={formatVariant(variant.variant)}
                        sx={{
                          backgroundColor: selectedVariant === variant.variant ? '#e8f5e9' : '#fafafa',
                          borderRadius: 2,
                          fontSize:"small",
                          px: 2,
                          py: 1,
                          m: 1,
                          // flexBasis: 'calc(50% - 16px)', 
                          boxShadow: selectedVariant === variant.variant ? '0 3px 5px rgba(0,0,0,0.2)' : 'none',
                          '&:hover': {
                            backgroundColor: selectedVariant === variant.variant ? '#e8f5e9' : '#f5f5f5',
                          },
                          fontSize: '14px', // Default font size for larger screens
                         
                        }}
                      />
                      </Grid>
                      </>
                    ))
                  )}
                </RadioGroup>

                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    backgroundColor: selectedVariant ? '#f44336' : '#f8d7da',
                    color: 'white',
                    padding: '8px 16px',
                    fontWeight: 'bold',
                    boxShadow: selectedVariant ? '0 4px 6px rgba(0,0,0,0.1)' : 'none',
                    '&:hover': {
                      backgroundColor: selectedVariant ? '#e53935' : '#f8d7da',
                    },
                  }}
                  endIcon={<ArrowForwardIcon />}
                  disabled={!selectedVariant}
                  onClick={handleGetExactValueClick}
                >
                  Get Exact Value
                </Button>

                {/* Section below "Get Exact Value" button */}
                <Box sx={{ 
                  backgroundColor: '#424242', 
                  color: 'white', 
                  padding: '15px 20px', 
                  borderRadius: 2,
                  mt: 2 
                }}>
                  <Typography variant="body2">
                    The price stated above depends on the condition of the device and is not final.<br/> 
                    The final price will be quoted at the end of the evaluation.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            </>
      )}

<FAQ  />
<Footer />
      
    </div>
  );
};

export default VariantSelectionCard;
