import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Card, CardMedia, Divider, CardContent } from '@mui/material';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MemoryIcon from '@mui/icons-material/Memory';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useDispatch, useSelector } from 'react-redux';

const DeviceEvaluation = () => {
const getImageUrl = (model) => {
    if (!model) return '';
    return model;
  };

   const DefectsselectedOptions = useSelector((state) => state.wrapper.DefectsselectedOptions);
    const ScreenselectedOptions = useSelector((state) => state.wrapper.ScreenselectedOptions);
    const functionalSelectedOptions = useSelector((state) => state.wrapper.functionalSelectedOptions);
     const accSelectedOptions =  useSelector((state) => state.wrapper.accSelectedOptions);
     const answers =  useSelector((state) => state.wrapper.answers);
     console.log(accSelectedOptions,"accSelectedOptions")
     const selectedModel = useSelector((state) => state.wrapper.selectedModel);
     const formattedVariant = useSelector((state) => state.wrapper.formattedVariant);
   

  // Add checks to ensure selectedModel and its properties are defined
  const brandName = !selectedModel.profile ? selectedModel["Brand name"] : selectedModel?.brandName ? selectedModel?.brandName : "N/A";
  const imageUrl = !selectedModel.profile ? selectedModel[" Image URL"] : selectedModel?.ImageURL ? selectedModel?.ImageURL : "";
  const model = !selectedModel.profile ? selectedModel["Model"] :  selectedModel?.model ? selectedModel?.model : "N/A" ;

  return (
    <div>
       <Box sx={{
        p: 2,
        border: '1px solid #ccc',
        borderRadius: 2,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        transition: 'box-shadow 0.3s ease',
        '&:hover': {
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
        },
      }}>
        <Card sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          height: 200,
          backgroundColor: '#f9f9f9',
        }}>
          <CardMedia
            component="img"
            alt={brandName}
            image={getImageUrl(imageUrl)}
            title={brandName}
            style={{
              width: '30%',
              padding:"2%",
              maxWidth: '80px',
              margin: 'auto',
              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
          />
          <CardContent sx={{ flex: 1, textAlign: 'center' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
          {`${brandName} ${model} (${formattedVariant})` || 'N/A'}
            </Typography>
          </CardContent>
        </Card> 
        
        <Divider sx={{ my: 2 }} />
        
        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>Device Evaluation</Typography>

        <Typography variant="h6" sx={{ mt: 2 }}>Device Details</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <PhoneAndroidIcon sx={{ mr: 1 }} />

        <Typography variant="body1">
          Able to Make and Receive Calls: {answers?.calls || 'N/A'}
        </Typography>
        </Box>


        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <ScreenRotationIcon sx={{ mr: 1 }} />
          <Typography variant="body1">
            Touch Screen: {answers?.touch || 'N/A'}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <VisibilityIcon sx={{ mr: 1 }} />
          <Typography variant="body1">
            Original Screen: {answers?.screenOriginal || 'N/A'}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <CheckCircleOutlineIcon sx={{ mr: 1 }} />
          <Typography variant="body1">
            Brand Warranty: {answers?.warranty || 'N/A'}
          </Typography>
        </Box>
       
        
        
        <Divider sx={{ my: 2 }} />
        
        <Typography variant="h6" sx={{ mt: 2 }}>Screen Conditions</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <ScreenRotationIcon sx={{ mr: 1 }} />
          <Typography variant="body1">
            Screen Physical Condition: {ScreenselectedOptions?.screenCondition?.text || 'N/A'}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <VisibilityIcon sx={{ mr: 1 }} />
          <Typography variant="body1">
            Dead Pixel Spots: {ScreenselectedOptions?.pixelSpots?.text || 'N/A'}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <HighlightOffIcon sx={{ mr: 1 }} />
          <Typography variant="body1">
            Visible Lines on Screen: {ScreenselectedOptions?.visibleLines?.text || 'N/A'}
          </Typography>
        </Box>


        <Divider sx={{ my: 2 }} />
        
        <Typography variant="h6" sx={{ mt: 2 }}>Body Defects</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <MemoryIcon sx={{ mr: 1 }} />
          <Typography variant="body1">
            Phone's Overall Condition: {DefectsselectedOptions?.overallCondition?.text || 'N/A'}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <HighlightOffIcon sx={{ mr: 1 }} />
          <Typography variant="body1">
            Body Defects: {DefectsselectedOptions?.bodyDefects?.text || 'N/A'}
          </Typography>
        </Box>
        
        
        
        <Divider sx={{ my: 2 }} />
        
        <Typography variant="h6" sx={{ mt: 2 }}>Functional Condition</Typography>
        {Array.isArray(functionalSelectedOptions?.functionalProblems) && functionalSelectedOptions?.functionalProblems.map((problem, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <MemoryIcon sx={{ mr: 1 }} />
            <Typography variant="body1">
              {problem.label || 'N/A'}
            </Typography>
          </Box>
        ))}
        <Divider sx={{ my: 2 }} />
        
        <Typography variant="h6" sx={{ mt: 2 }}>Do you have the following?</Typography>
       
        {Array.isArray(accSelectedOptions) && accSelectedOptions?.map((problem, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <CheckCircleOutlineIcon sx={{ mr: 1 }} />
            <Typography variant="body1">
              {problem?.label || 'N/A'}
            </Typography>
          </Box>
        ))}

      </Box>
    </div>
  );
};

export default DeviceEvaluation;
