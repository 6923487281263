 import { createSlice } from '@reduxjs/toolkit';
 const initialState = {
  rowDetails: {},
  formattedVariant : "",
  selectedModel : null,
  showAppbar:true,
  screenDefects:{
    screenCondition: [],
    defectsCondition: [],
  },
  city:"Mysore",
  currentValue:0,
  deviceCurrValue:0,
  warrantyStatus : false,
  answers : {
    calls: '',
    touch: '',
    screenOriginal: '',
    warranty: '',
  },
 
  ScreenselectedOptions : {
    screenCondition: null,
    pixelSpots: null,
    visibleLines: null
  },
  DefectsselectedOptions : {
    overallCondition : null,
    bodyDefects: null,
  },
  functionalSelectedOptions : {
    "functionalProblems":[]
  },
  accSelectedOptions : [],
  FixedPrice : 0,
  selectedWarranty : "",
  userDetails : {
    name : "",
    email : "",
    phoneNumber : ""
  },
  orderDetails : [],
  cancelDialog :false,
  updateAddress : "",
  newUser:false,
  isLogged:false
  };


const wrapperSlice = createSlice({
  name: 'wrapper',
  initialState,
  reducers: {
    handleMount: (state, action) => {
       state.rowDetails = action.payload;
    },
    handlePickupDetails: (state, action) => {
     state.handlePickupDetails = action.payload;
    
  },
  handleWarrantyStatus : (state,action) => {
    state.warrantyStatus = action.payload;
  },
  
  handleselectedModelDetails : (state,action) => {
    state.selectedModelDetails = action.payload;
  },
  handleformattedVariant : (state,action) => {
    state.formattedVariant = action.payload
  },
  handleselectedModel : (state,action) => {
    state.selectedModel = action.payload
  },
  handlecity : (state,action) => {
    state.city = action.payload
  },
  handlecurrentValue : (state,action) => {
    state.currentValue = action.payload;
  },
  handleAnswers : (state,action) => {
    state.answers = action.payload;
  },
  handleScreenselectedOptions : (state,action) => {
    state.ScreenselectedOptions = action.payload;
  },
  handleDefectsselectedOptions : (state,action) => {
    state.DefectsselectedOptions = action.payload;
  },
  handlefunctionalSelectedOptions: (state,action) => {
    state.functionalSelectedOptions = action.payload;
  },
  handleaccSelectedOptions: (state,action) => {
    state.accSelectedOptions = action.payload;
  },
  handleFixedPrice: (state,action) => {
    state.FixedPrice = action.payload;
  },
  handleselectedWarranty: (state,action) => {
    state.selectedWarranty = action.payload;
  },
  
  handleuserDetails : (state,action) => {
    state.userDetails = action.payload;
  },
  handleOrderDetails : (state,action) => {
    state.orderDetails = action.payload;
  },
   
  handleCancelDialog : (state,action) => {
    state.cancelDialog = action.payload;
  },
  handleupdateAddress : (state,action) => {
    state.updateAddress = action.payload
  },
  handleNewUser :(state,action) => {
    state.newUser = action.payload;
  },
  handleIsLogged :(state,action) => {
    state.isLogged = action.payload;
    // localStorage.setItem('isLogged', JSON.stringify(action.payload)); // Store in localStorage
  },
  handleAppbar:(state,action) => {
    state.showAppbar = action.payload;
    // localStorage.setItem('isLogged', JSON.stringify(action.payload)); // Store in localStorage
  },
  handleScreenDefects:(state,action) => {
    state.screenDefects = action.payload;
  },
  handleDeviceCurrvalue : (state,action) =>{
    state.deviceCurrValue = action.payload;
  }
  },
});

export const {
  handleMount,
  handlePickupDetails,
  handleselectedModelDetails,
  handleformattedVariant,
  handlecity,
  handleselectedModel,
  handlecurrentValue,
  handleWarrantyStatus,
  handleAnswers,
  handleScreenselectedOptions,
  handleDefectsselectedOptions,
  handlefunctionalSelectedOptions,
  handleaccSelectedOptions,
  handleFixedPrice,
  handleuserDetails,
  handleselectedWarranty,
  handleOrderDetails,
  handleCancelDialog,
  handleupdateAddress,
  handleNewUser,
  handleIsLogged,
  handleAppbar,
  handleScreenDefects,
  handleDeviceCurrvalue
} = wrapperSlice.actions;
export default wrapperSlice.reducer;
