import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Container, Typography, Box, Button, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
  root: {
    padding: "4%",
    backgroundColor: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: "1px",
    maxWidth: 900,
    margin: 'auto',
    marginTop:"4%",
  },
  title: {
    marginBottom: "3%",
    color: '#06A89D',
  },
  section: {
    marginBottom: "3%",
  },
  listItem: {
    marginBottom: "1%",
  },
}));

const TermsAndConditions = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate('/');
  };

  return (
    
      <Container className={classes.root}>
        <IconButton onClick={handleBackClick} >
         <Button className='go-back-button' startIcon={<ArrowBackIcon className='go-back-icon'/>}>
           <Typography variant="body1" className='go-back-text' sx={{ fontWeight: 'bold' }} color="#4BCEBD">
         Go back
        </Typography>
         </Button> 
        </IconButton>
      <Typography variant="h4" className={classes.title} gutterBottom>
        Terms and Conditions
      </Typography>

      <Box className={classes.section}>
        <Typography variant="h5" gutterBottom>
          1. Introduction
        </Typography>
        <Typography variant="body1">
          Welcome to Cashonspot By accessing or using our mobile website/application, you agree to be bound by these terms and conditions ("Terms"). Please read them carefully before using the website.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" gutterBottom>
          2. Use of the Website
        </Typography>
        <ul>
          <li className={classes.listItem}>
            <Typography variant="body1">You should reach your legal age to form a contract.</Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">The website is intended for personal use only. You agree not to use the website for any commercial purposes without our consent.</Typography>
          </li>
        </ul>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" gutterBottom>
          3. Account Registration
        </Typography>
        <ul>
          <li className={classes.listItem}>
            <Typography variant="body1">You may be required to create an account to use certain features of the website. You agree to provide accurate, current, and complete information during the registration process.</Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</Typography>
          </li>
        </ul>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" gutterBottom>
          4. Purchases and Payments
        </Typography>
        <ul>
          <li className={classes.listItem}>
            <Typography variant="body1">All purchases made through the website are final and non-refundable, except as required by law.</Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">Prices for products and services are subject to change without notice.</Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">By making a purchase, you agree to pay the listed price and any applicable taxes and fees.</Typography>
          </li>
        </ul>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" gutterBottom>
          5. Intellectual Property
        </Typography>
        <Typography variant="body1">
          The website and its content, including but not limited to text, graphics, logos, and software, are owned by Cashonspot and are protected by intellectual property laws.
          You may not reproduce, distribute, or otherwise use the website's content without our prior written permission.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" gutterBottom>
          6. User Content
        </Typography>
        <Typography variant="body1">
          You may have the ability to submit, upload, or post content through the website. By doing so, you grant us a non-exclusive, worldwide, royalty-free license to use, modify, and display your content in connection with the website.
          You agree not to submit content that is unlawful, offensive, or infringes on the rights of others.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" gutterBottom>
          7. Privacy
        </Typography>
        <Typography variant="body1">
          Your use of the website is also governed by our Privacy Policy, which explains how we collect, use, and protect your personal information.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" gutterBottom>
          8. Disclaimer of Warranties
        </Typography>
        <Typography variant="body1">
          The Website is provided "as is" and "as available." We do not guarantee that the Website will be error-free or uninterrupted.
          We disclaim all warranties, express or implied, regarding the website and its content.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" gutterBottom>
          9. Limitation of Liability
        </Typography>
        <Typography variant="body1">
          To the fullest extent permitted by law, Cashonspot shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the Website.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" gutterBottom>
          10. Indemnification
        </Typography>
        <Typography variant="body1">
          You agree to indemnify and hold harmless Cashonspot, its affiliates, and its employees from any claim, damages, or expenses arising from your use of the website or violation of these Terms.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" gutterBottom>
          11. Changes to the Terms
        </Typography>
        <Typography variant="body1">
          We reserve the right to modify these Terms at any time. Any changes will be effective upon posting on the Website. Your continued use of the Website after such changes constitutes your acceptance of the new Terms.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" gutterBottom>
          12. Governing Law
        </Typography>
        <Typography variant="body1">
          These Terms are governed by the laws .Any disputes arising from these Terms or your use of the website will be resolved in the courts of Republic India.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" gutterBottom>
          13. Contact Information
        </Typography>
        <Typography variant="body1">
          If you have any questions or concerns about these Terms, please contact us at
          Email: Cashonspotmysore@gmail.com
          Time: Monday to Saturday (10:00am – 4:00pm)
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsAndConditions;
