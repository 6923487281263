import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Container, Typography, Box, Button, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
  root: {
    padding: "4%",
    backgroundColor: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: "1px",
    maxWidth: 900,
    margin: 'auto',
    marginTop:"4%",
  },
  title: {
    marginBottom: "3%",
    color: '#06A89D',
  },
  section: {
    marginBottom: "3%",
  },
  listItem: {
    marginBottom: "1%",
  },
}));

const TermsAndConditions = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate('/');
  };

  return (
    
      <Container className={classes.root}>
        <IconButton onClick={handleBackClick} className={classes.backButton}>
         <Button className='go-back-button' startIcon={<ArrowBackIcon className='go-back-icon' />}>
          <Typography variant="body1" className='go-back-text' sx={{ fontWeight: 'bold' }} color="#4BCEBD">
                 Go back
                </Typography>
         </Button> 
        </IconButton>
      <Typography variant="h4" className={classes.title} gutterBottom>
      Cookie Policy
      </Typography>

      <Box className={classes.section}>

        <Typography variant='p'>
        Last updated: 20/10/2024
At cashonspot, we use cookies and similar tracking technologies to enhance your browsing experience, understand user behavior, and provide personalized services. This Cookie Policy explains what cookies are, how we use them, and your choices regarding cookies.
        </Typography>
        <Typography variant="h5" gutterBottom>
          1. What Are Cookies?
        </Typography>
        <Typography variant="body1">
        Cookies are small text files stored on your device (computer, tablet, or mobile) when you visit a website. They allow websites to recognize your device and store information about your preferences or actions for a set period.        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" gutterBottom>
          2. Types of Cookies We Use
        </Typography>
        <ul>
          <li className={classes.listItem}>
            <Typography variant="body1">We use the following types of cookies on our website:

a. Essential Cookies

These cookies are necessary for the operation of our website. They enable you to navigate and use our website features, such as accessing secure areas.

b. Analytical/Performance Cookies

These cookies collect information about how visitors use our website, such as the pages they visit most often. This helps us improve the way our website works.

c. Functionality Cookies

These cookies allow our website to remember your preferences (such as language selection) and provide enhanced, more personal features.

d. Advertising/Targeting Cookies

These cookies track your browsing habits and are used to deliver relevant ads based on your interests. They may also limit the number of times you see an advertisement and help measure the effectiveness of advertising campaigns.</Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">The website is intended for personal use only. You agree not to use the website for any commercial purposes without our consent.</Typography>
          </li>
        </ul>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" gutterBottom>
          3. How We Use Cookies
        </Typography>
        <ul>
          <li className={classes.listItem}>
            <Typography variant="body1">We use cookies to:

•	Enhance user experience
•	Analyze website traffic and usage patterns
•	Personalize content and ads
•	Improve website functionality</Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</Typography>
          </li>
        </ul>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" gutterBottom>
          4. Third-Party Cookies
        </Typography>
        <ul>
          <li className={classes.listItem}>
            <Typography variant="body1">We may allow third-party service providers, such as analytics or advertising companies, to place cookies on your device. These third parties may use the information collected by cookies to serve targeted advertisements on our website or other websites you visit.</Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">Prices for products and services are subject to change without notice.</Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">By making a purchase, you agree to pay the listed price and any applicable taxes and fees.</Typography>
          </li>
        </ul>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" gutterBottom>
          5. Your Choices Regarding Cookies
        </Typography>
        <Typography variant="body1">
        You can manage or delete cookies using your browser settings. You may also be able to block cookies by adjusting your browser settings to reject all or some cookies. However, if you block or delete cookies, some parts of the website may not function properly.

To learn more about how to manage cookies, visit [Browser Settings Instructions] or consult the help section of your browser.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" gutterBottom>
          6. Changes to This Cookie Policy
        </Typography>
        <Typography variant="body1">
        We may update this Cookie Policy from time to time to reflect changes in our practices or legal requirements. We will post any updates on this page and encourage you to review this policy periodically.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" gutterBottom>
          7. Contact Us
        </Typography>
        <Typography variant="body1">

        If you have any questions or concerns about this Cookie Policy, please contact us at:

sellatcashonspit.in@gmail.com
Cashonspot.in (SM enterprises)
Mysore, Karnataka        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" gutterBottom>
          8. Disclaimer of Warranties
        </Typography>
        <Typography variant="body1">
          The Website is provided "as is" and "as available." We do not guarantee that the Website will be error-free or uninterrupted.
          We disclaim all warranties, express or implied, regarding the website and its content.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" gutterBottom>
          9. Limitation of Liability
        </Typography>
        <Typography variant="body1">
          To the fullest extent permitted by law, Cashonspot shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the Website.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" gutterBottom>
          10. Indemnification
        </Typography>
        <Typography variant="body1">
          You agree to indemnify and hold harmless Cashonspot, its affiliates, and its employees from any claim, damages, or expenses arising from your use of the website or violation of these Terms.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" gutterBottom>
          11. Changes to the Terms
        </Typography>
        <Typography variant="body1">
          We reserve the right to modify these Terms at any time. Any changes will be effective upon posting on the Website. Your continued use of the Website after such changes constitutes your acceptance of the new Terms.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" gutterBottom>
          12. Governing Law
        </Typography>
        <Typography variant="body1">
          These Terms are governed by the laws .Any disputes arising from these Terms or your use of the website will be resolved in the courts of Republic India.
        </Typography>
      </Box>

      <Box className={classes.section}>
        <Typography variant="h5" gutterBottom>
          13. Contact Information
        </Typography>
        <Typography variant="body1">
          If you have any questions or concerns about these Terms, please contact us at
          Email: Cashonspotmysore@gmail.com
          Time: Monday to Saturday (10:00am – 4:00pm)
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsAndConditions;
