import React, { useState, useEffect } from 'react';
import { Container, Typography, Paper, Grid, Button,Radio,FormLabel,FormControl, TextField,RadioGroup, FormControlLabel,Box, FormHelperText,Avatar, IconButton,Alert,DialogContentText, CircularProgress,Dialog, DialogTitle, DialogContent,DialogActions} from '@mui/material';
import { styled } from '@mui/system';
import { Email, Edit, CheckCircle, Cancel, PendingActions, Person } from '@mui/icons-material';
import Tick from '../../assets/images/Tick.jpeg'; // Replace with your image path
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useLocation } from 'react-router-dom';
import { addDays, isBefore, startOfToday, setHours, setMinutes } from 'date-fns';
import {List, ListItem, ListItemText, Divider, BottomNavigation, BottomNavigationAction } from "@mui/material";
import Navbar from '../commonForms/Navbar';
import axios from 'axios';
import { config } from '../../config';
import CancelOrderDialog from './CancelReason.js';
 import DeviceEvaluation from '../commonForms/DeviceEvaluation.js';
 import { LocalizationProvider, DatePicker, TimePicker} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  
  Business as BusinessIcon,
  Star
  
} from '@mui/icons-material';
import { 
 
  MenuItem, Select,InputLabel,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear"
import { LocationOn, Settings, Storefront, Info, ShoppingCart} from "@mui/icons-material";
import CustomerLoginInitial from "./CustomerLoginInitial.js";
import { useDispatch, useSelector } from 'react-redux';
import {handleuserDetails,handleOrderDetails,handleIsLogged} from "../../redux/WrapperSlice"
import EditIcon from "@mui/icons-material/Edit";

const ProfileContainer = styled(Container)({
  marginTop: '20px',
});

const ProfileCard = styled(Paper)({
  padding: '20px',
  textAlign: 'center',
  position: 'relative',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  },
});

const CancelledOrderCard = styled(Paper)({
  padding: '20px',
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  },
});

 

const VerificationPendingBox = styled(Box)({
  backgroundColor: '#fff3cd',
  padding: '10px',
  marginTop: '10px',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
const disablePastDates = (date) => {
  return isBefore(date, startOfToday());
};



const getStatusIcon = (status) => {
  switch (status) {
    case 'Completed':
      return <CheckCircle />;
    case 'Failed':
      return <Cancel />;
    case 'Pending':
    default:
      return <PendingActions />;
  }
};

const getStatusColor = (status) => {
  switch (status) {
    case 'Completed':
      return 'green';
    case 'Failed':
      return 'red';
    case 'Pending':
    default:
      return 'orange';
  }
};





const ProfilePage = () => {
const dispatch = useDispatch();
const navigate = useNavigate();
const rowDetails3 = useSelector((state) => state.wrapper.rowDetails);
const userDetails1 = useSelector((state) => state.wrapper.userDetails);
const isLogged = useSelector((state) => state.wrapper.isLogged);
const location = useLocation();
const {name,email,PhoneNumber} = location.state || {}
const city = useSelector((state) => state.wrapper.city);
const [userDetails, setUserDetails] = useState([]);
const [finalAmounts, setFinalAmounts] = useState({});
const [loading, setLoading] = useState(false);
const [loadingStates, setLoadingStates] = useState({});
const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
const[OrderCancelled,setOrderCancelled] = useState(false);
const [reportDialogOpen, setReportDialogOpen] = useState(false); // State for report dialog
const [currentPage, setcurrentPage] = useState('Profile');
const [OrderID,setOrderID] = useState(null);
const[selectModel,setselectModel] = useState({model:"",brandName:"",ImageURL:"",profile:true});
const[formattedVariant,setformattedVariant] = useState(null);
const [deviceAnswers,setdeviceAnswers] = useState({});
const [answers, setAnswers] = useState({});
const [pickupTimeOption, setPickupTimeOption] = useState('10am to 2pm'); // State for pickup time option
const [formData, setFormData] = useState({pickupTimeOption:null,pickupDate:null})
const [errors, setErrors] = useState({});
const [message, setMessage] = useState('');
const [messageType, setMessageType] = useState(''); 
 const[RescheduleOpen,setRescheduleOpen] = useState(false);
 const[RescheduleID,setRescheduleID] = useState(null);
 const [selectedDate, setSelectedDate] = useState(null);
 const [selectedTime, setSelectedTime] = useState(null);
 const [cancelledOrders, setCancelledOrders] = useState({});
 const[OpenPayment,setOpenPayment] = useState(false);
 const [paymentMethod, setPaymentMethod] = useState("");
 const [localUserDetails, setLocalUserDetails] = useState(null);

 const [openEdit, setOpenEdit] = useState(false);


 useEffect(() => {
  const storedData = JSON.parse(localStorage.getItem("userDetails"));
  if (storedData) {
    setLocalUserDetails(storedData); // Update local state immediately
    dispatch(handleuserDetails(storedData)); // Dispatch to Redux
  }
}, []);


const userData = userDetails1?.name ? userDetails1 : localUserDetails; // Use local state if Redux is not ready
const [editedUser, setEditedUser] = useState({
  name: userData?.name || "",
  email: userData?.email || "",
  phoneNumber: userData?.phoneNumber || "",
});

 const handleOpenEdit = () => {
  setEditedUser({
    name: userData?.name || "",
    email: userData?.email || "",
    phoneNumber: userData?.phoneNumber || "",
  });
  setOpenEdit(true);
};

const handleCloseEdit = () => {
  setOpenEdit(false);
};

const handleChange = (e) => {
  const { name, value } = e.target;
  setEditedUser((prev) => ({ ...prev, [name]: value }));
};

const handleUpdateDetails = async () => {
  try {
   const response = await axios.post(`${config.Api}update-user-details`, {
      phoneNumber: userData?.phoneNumber, // Assuming phoneNumber is the unique identifier
      name: editedUser.name,
      email: editedUser.email,
      newPhoneNumber: editedUser.phoneNumber, // Optional: allow updating phoneNumber
    });
    dispatch(handleuserDetails(response.data.user))
    setOpenEdit(false);
    
  } catch (error) {
    console.error("Failed to update user details:", error.response?.data || error.message);
  }
};


const handlePickupTimeOptionChange = (e) => {
  const value = e.target.value;
  setPickupTimeOption(value);
  setFormData(prevData => ({
    ...prevData,
    pickupTimeOption: value,
  }));
};
 

const  handleRescheduleSubmit = async () => {
  setLoading(true);
  // Basic validation
  const newErrors = {};
   if (!formData.pickupDate) newErrors.pickupDate = 'Pickup date is required';
  if (!formData.pickupTimeOption) newErrors.pickupTimeOption = 'Pickup time is required';
  if (Object.keys(newErrors).length === 0) {
    try {
      const url = RescheduleID ? `${config.Api}userpickup/${RescheduleID}` : config.Api+'userpickup';

    const method = RescheduleID ? 'POST' : 'POST';
    const response = await fetch(url, {
      method: method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    });

    ////console.log(formData,"formData")

      if (response.ok) {
        const result = await response.json(); // Parse the JSON response
        ////console.log(result,"result");
         alert('Order has been rescheduled SuccessFully');
         setRescheduleOpen(false);
         fetchUserDetails();
         setMessageType('success');
         
     
        // Clear form data after successful submission if needed
        setFormData({
          pickupDate: null,
          pickupTimeOption: '10am to 2pm', // Reset to default value
         
        });
      } else {
        // setPickupDetails([]);
        throw new Error('Failed to Reschedule');
      }
    } catch (error) {
      setMessage('Error submitting details. Please try again.');
      setMessageType('error');
     }finally {
      setLoading(false); // Stop loading after processing
    }
  } 
  else {
    setErrors(newErrors);
       setMessageType('error');
    setLoading(false); // Stop loading even if there are validation errors
  }
};


const handleDateSelect = (date) => {
  const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

  setSelectedDate(utcDate);
  setFormData(prevData => ({
    ...prevData,
    pickupDate: utcDate,
  }));
  setSelectedTime(null); // Reset time when date changes
};
const handleReportOpen = () => {
  setReportDialogOpen(true); 
};
const handleReportClose = () => {
  setReportDialogOpen(false);
  fetchUserDetails();
}; 
 
const fetchUserDetails = async () => {
  try {
    const response = await axios.post(`${config.Api}orderDetails`, {phoneNumber:userData?.phoneNumber});
    //console.log(response.data.records,"response.data.records");
    ////console.log(response.data?.records,"Proofs",response?.data?.records[0]?.Model,"MODELLL"  );
    ////console.log(response?.data?.records?.Answers,"response?.data?.records?.Answers")
    setAnswers(response?.data?.records[0]?.Answers);
    setdeviceAnswers(response?.data?.records[0]?.deviceAnswers)
    setselectModel({...selectModel,model:response?.data?.records[0]?.Model,brandName:response?.data?.records[0]?.["Brand Name"],ImageURL:response?.data?.records[0]?.ImageURL});
    // setselectModel({...selectModel,brandName:response?.data?.records[0]?.["Brand Name"]});
    setformattedVariant(response?.data?.records[0]?.formattedVariant);

    // setreportData(proof)
    // setUserDetails1(response1.data);
    setUserDetails(response.data.records); // Assuming response.data is an array
    dispatch(handleOrderDetails(response.data.records));
  } catch (error) {
    console.error('Error fetching user details:', error);
    alert('Error fetching user details');
  }
};

  useEffect(() => {
   if (email) {
      fetchUserDetails();
    }
  },[email]);



  

  const handleDeleteAccount = async () => {
    try {
      const response = await axios.post(`${config.Api}delete/${userData?.phoneNumber}`);
      //console.log(response.data);
      alert('Your account has been deleted successfully!');
  
      // Clear local state and logout the user
      dispatch(handleuserDetails({ name: '', email: '', phoneNumber: '' }));
    } catch (error) {
      console.error('Failed to delete account:', error.response?.data || error.message);
      alert('Failed to delete account. Please try again later.');
    }
  };


const handleCancelOrder = (id) => {
setCancelDialogOpen(true);
setOrderID(id);
}
const handleReschedule = (id) => {
   setRescheduleOpen(true);
   setRescheduleID(id)
}

const handleDeviceReport = (id) => {
  fetchUserDetails(id);
  handleReportOpen();
}

const handleCancelClose = () => {
  setCancelDialogOpen(false);
};

const handleCancelConfirm = async (reason) => {
  ////console.log(OrderID,"OrderID")
  try {
   
    const status = 'Failed'; // Define the status as needed

    const response = await axios.post(`${config.Api}update-status/${OrderID}`, { status, reason });

    if (response.status === 200) {
     alert("Order cancelled successfully");
     setOrderCancelled(true);

       // Update the specific order ID in the cancelledOrders state
       setCancelledOrders(prevCancelledOrders => ({
        ...prevCancelledOrders,
        [OrderID]: true,  // Mark this order as cancelled
      }));
     fetchUserDetails();
      // You can add any additional logic here if needed, like showing a success message to the user
    } else {
      alert("Failed to cancel order");
      setOrderCancelled(false);
        // Update the specific order ID in the cancelledOrders state
        setCancelledOrders(prevCancelledOrders => ({
          ...prevCancelledOrders,
          [OrderID]: true,  // Mark this order as cancelled
        }));
      fetchUserDetails();

      // You can add any additional logic here if needed, like showing an error message to the user
    }
  } catch (error) {
    console.error("Error cancelling order:", error);
    // Handle error appropriately, maybe show an error message to the user
  }

  setCancelDialogOpen(false);
};

const handleNavigation = (route) => {
  if (route === "whatsapp") {
    window.open("https://wa.me/8296961413", "_blank", "noopener,noreferrer");
  } else {
    fetchUserDetails();
    navigate(route);
  }
};




 

  return (
 
    <>
<Navbar/> 
{currentPage === "Profile" && (
  <>
  <Box sx={{ width: "100%", maxWidth: 400, mx: "auto", bgcolor: "background.default", p: 2 }}>
      {/* Location Selection */}
      <Box display="flex" alignItems="center" gap={1} mb={2}>
        <LocationOn color="primary" />
        <Typography variant="body1">{city}</Typography>
      </Box>

      {/* Login Card */}
      <Box
      sx={{
        bgcolor: userData?.name ? "black" : "black",
        color: userData?.name ? "white" : "white",
        borderRadius: 3,
        p: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {userData?.name ? (
        <Box>
          <Typography variant="h6" fontWeight="bold">
            {userData?.name.toUpperCase()}
            <IconButton onClick={handleOpenEdit} sx={{ color: "white"}}>
          <EditIcon />
        </IconButton>
          </Typography>
          <Typography variant="body2">
            +91 {userData?.phoneNumber} | {userData?.email}
          </Typography>

        

          {/* Orders, Chat & Refer Section */}
    
        </Box>
      ) : (
        <Box>
          <Typography variant="h6" fontWeight="bold">Hello</Typography>
          <Typography variant="body2">Please login/signup</Typography>
        </Box>
      )}
      



      {!userData?.name && (
        <Button
          onClick={() => navigate("/login")}
          variant="contained"
          sx={{ bgcolor: "white", color: "black", borderRadius: 2 }}
        >
          Login
        </Button>
      )}
    </Box>


    {/* Orders, Chat & Refer Section */}
    {userData?.name && (
      <>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {[
          { label: "Manage Orders", icon: "📦", route: "/my-orders" },
          { label: "Chat with Us", icon: "💬", route: "whatsapp" },
          { label: "Refer & Earn", icon: "❤️", route: "/refer" },
          ].map((item, index) => (
            <Grid item xs={4} key={index}>
              <Paper
                onClick={() => handleNavigation(item.route)}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  p: 2,
                  borderRadius: 2,
                  textAlign: "center",
                  cursor: "pointer",
                  boxShadow: 3,
                }}
              >
                <Typography variant="h5">{item.icon}</Typography>
                <Typography variant="body2" fontWeight="bold">
                  {item.label}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
  
               </>
      )}

      {/* Settings List */}
      <Box mt={3}>
       
      {userData && userData?.name && userData?.email && userData?.phoneNumber && (

 <List>

<ListItem onClick={() => navigate("/manage-address", { state: { phoneNumber: userData?.phoneNumber} })}>
<ListItemText primary={"Manage Address"} />
</ListItem>
<Divider />
 
<ListItem key={"Home"}   
onClick={() => {
 navigate("/")
}}
style={{gap:"5%",color:"red",textAlign:"left",cursor:"pointer"}} 
>
<ListItemText primary={"Home"} />
</ListItem> 
 
<Divider />
<ListItem key={"Log out"}   
onClick={() => {
  // Clear Redux state
  dispatch(handleuserDetails({ name: "", email: "", phoneNumber: "" }));
  dispatch(handleIsLogged(false));
  navigate("/")

  // Clear localStorage
  localStorage.removeItem("userDetails");
  localStorage.removeItem("isLogged"); 

  // Optional: Reload the page to reset the state completely
  // window.location.reload();
  // navigate
}}
style={{gap:"5%",color:"red",textAlign:"left",cursor:"pointer"}} 
>
<ListItemText primary={"Log out"} />
</ListItem>


</List>  
        )} 

 {!isLogged && (
<List>
<ListItem key={"Home"}   
onClick={() => {
 navigate("/")
}}
style={{gap:"5%",color:"red",textAlign:"left",cursor:"pointer"}} 
>
<ListItemText primary={"Home"} />
</ListItem> 
</List>        
 )}
      </Box>


   

      
       

   

    </Box>
 
      <Dialog open={OpenPayment} onClose={() => setOpenPayment(false)}>
        <DialogTitle>Select Payment Method</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel>Select Payment</InputLabel>
            <Select 
              value={paymentMethod} 
              onChange={(e) => setPaymentMethod(e.target.value)} 
              endAdornment={
                paymentMethod && (
                  <IconButton size="small" onClick={() => setPaymentMethod("")}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                )
              }
            >
              <MenuItem value="Paytm">Paytm</MenuItem>
              <MenuItem value="Google Pay">Google Pay</MenuItem>
              <MenuItem value="PhonePe">PhonePe</MenuItem>
              <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
            </Select>
          </FormControl>
          <Button 
            variant="contained" 
            color="primary" 
            fullWidth 
            onClick={() => setOpenPayment(false)} 
            disabled={!paymentMethod}
            style={{ marginTop: "10px" }}
          >
            Save
          </Button>
        </DialogContent>
      </Dialog>

      <Dialog open={openEdit} onClose={handleCloseEdit}>
        <DialogTitle>Edit Profile</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="dense"
            label="Name"
            name="name"
            value={editedUser.name}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="dense"
            label="Email"
            name="email"
            disabled
            value={editedUser.email}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="dense"
            label="Phone Number"
            name="phoneNumber"
            disabled
            value={editedUser.phoneNumber}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEdit}>Cancel</Button>
          <Button onClick={handleUpdateDetails} variant="contained">
            Update
          </Button>
        </DialogActions>
      </Dialog>
      </>
)}

{currentPage === "customer-login" && (
  <CustomerLoginInitial/>
)}



</>
 
  
  );
};

export default ProfilePage;
