import React, { useState,useEffect} from 'react';
import { Container, Grid, Typography, Button,useMediaQuery,  Box, Paper} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeviceEvaluation from '../commonForms/DeviceEvaluation'; // Update the import path as necessary
import Accessories from './Accessories'; // Update the import path as necessary
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Defects from './Defects';
import image1 from '../../assets/images/F1.jpg';
import image2 from '../../assets/images/F2.jpg';
import image3 from '../../assets/images/F4.jpg';
import image17 from "../../assets/images/camera-glass.jpeg";
import image4 from '../../assets/images/F3.jpg';
import image5 from '../../assets/images/F5.jpg';
import image6 from '../../assets/images/F6.jpg';
import image7 from '../../assets/images/F7.jpg';
import image8 from '../../assets/images/F8.jpg';
import image9 from '../../assets/images/F9.jpg';
import image10 from '../../assets/images/F10.jpg';
import image11 from '../../assets/images/F11.jpg';
import image12 from '../../assets/images/F12.jpg';
import image13 from '../../assets/images/F13.jpg';
import image14 from '../../assets/images/F14.jpg';
import image15 from '../../assets/images/F15.jpg';
import image16 from '../../assets/images/F16.jpg';
import Footer from '../commonForms/Footer';
import FAQ from '../commonForms/FAQ';
import { useDispatch, useSelector } from 'react-redux';
import {handlefunctionalSelectedOptions,handlecurrentValue} from "../../redux/WrapperSlice";
import { useNavigate, useParams,useLocation} from 'react-router-dom';
import ScreenDefects from './ScreenDefects';


const Functional = ({ 
   userLogDetails,
   onFormData
  }) => {
  const [selectedOptions, setSelectedOptions] = useState({"functionalProblems":[]});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productType,brand,model,variant,step1,step2,step3,step4} = useParams();
  const [showAccessories, setShowAccessories] = useState(false);
  const [showFunctional, setShowFunctional] = useState(true);
  const [showDefects, setShowDefects] = useState(false);
   const  reduxfuncselected = useSelector((state) => state.wrapper.functionalSelectedOptions);
    console.log(reduxfuncselected,"functionalSelectedOptions")
  let currentValue = useSelector((state) => state.wrapper.currentValue);
  console.log(currentValue,"Functional")
   const[showWarrantyPage1,setshowWarrantyPage] = useState(true);
  const[showScreenDefects,setshowScreenDefects] = useState(false);
  const selectedModel = useSelector((state) => state.wrapper.selectedModel);
  const answers =  useSelector((state) => state.wrapper.answers);
  const formattedVariant = useSelector((state) => state.wrapper.formattedVariant);
  const FixedPrice = useSelector((state) => state.wrapper.FixedPrice);
  const city = useSelector((state) => state.wrapper.city);
  const rowDetails = useSelector((state) => state.wrapper.rowDetails)
  const DefectsselectedOptions = useSelector((state) => state.wrapper.DefectsselectedOptions);
  const ScreenselectedOptions = useSelector((state) => state.wrapper.ScreenselectedOptions) 
  
   useEffect(() => {
    navigate(`/sell/${productType}/${brand}/${model}/${variant}/${step1}/${step2}/${step3}/${step4}/functional`, { replace: true });
          
        }, []);

        
         useEffect(() => {
            if (reduxfuncselected) {
              setSelectedOptions(reduxfuncselected); // Set previously selected values
            }
          }, []); 

  const handleSelection = (categoryId, option) => {
    setSelectedOptions((prevSelectedOptions) => {
      const currentSelected = prevSelectedOptions[categoryId] || [];
      const isSelected = currentSelected.some((selectedOption) => selectedOption.label === option.label);

      if (isSelected) {
        const updatedSelection = currentSelected.filter((selectedOption) => selectedOption.label !== option.label);
        console.log("Deselected:", option); // Track deselected items

        
        let resetValue = (currentValue - option?.deduction);
        console.log(resetValue,"resetValue");

        const warrantyConditions = [
          "touch",
          "screenOriginal",
          "Screen cracked/ glass broken",
          "Average:-major scraches, major dents,panel crackes, loose screen gap",
          "Below Average:-Heavy scraches,Heavy dents and phone bent",
          "Phone Bent",
          "Phone Bent With Panel Crack",
          "Only Panel Broken"
        ];
        
        // Check if any warranty condition matches from the answers object
        const hasWarrantyIssue = warrantyConditions.some(condition => 
          Object.values(answers).includes(condition)
        );
        
        // Check if any of the answers related to warranty are "no"
        const isWarrantyDeclined = answers.warranty === "no" || answers.touch === "no" || answers.screenOriginal === "no";
        
        // Combine both checks: If any one of these conditions is met, trigger the warranty deduction
        const shouldDeductWarranty = isWarrantyDeclined || hasWarrantyIssue;
        
        if (shouldDeductWarranty) {
          resetValue -= rowDetails["> 11 months"];
          dispatch(handlecurrentValue(resetValue));
          setshowWarrantyPage(false);
        }
        dispatch(handlecurrentValue(resetValue));
        dispatch(handlefunctionalSelectedOptions({
          ...selectedOptions, 
          [categoryId]: updatedSelection
        }))
        
        return {
          ...prevSelectedOptions,
          [categoryId]: updatedSelection,
        };
      } else {
        dispatch(handlefunctionalSelectedOptions({ [categoryId]: currentSelected.concat(option)}))

        return {
          ...prevSelectedOptions,
          [categoryId]: currentSelected.concat(option),
        };
        
      }
    });
  };

  const categories = [
    {
      id: 'functionalProblems',
      title: 'Physical or Functional Problems',
      subtitle: 'Choose exact condition to get accurate value',
      options: [
        { label: 'Front Camera not working', imageUrl: image1,deduction: rowDetails['Front Camera'] },
        { label: 'Back Camera not working', imageUrl: image2,deduction: rowDetails['Back Camera'] },
        { label: 'Camera Glass', imageUrl: image17,deduction: rowDetails['Camera Glass']},
        { label: 'Face Sensor not working', imageUrl: image3,deduction: rowDetails['Face Sensor'] },
        { label: 'Finger Touch not working', imageUrl: image4,deduction: rowDetails['Finger Touch'] },
        { label: 'WiFi not working', imageUrl: image5 ,deduction: rowDetails['Wifi']},
        { label: 'Bluetooth not working', imageUrl: image6 ,deduction: rowDetails['Bluetooth']},
        { label: 'Volume Button not working', imageUrl: image7,deduction: rowDetails['Volume Button ']},
         { label: 'Power Button not working ', imageUrl: image8,deduction: rowDetails['Power buttons'] },
        { label: 'Vibrator is not working', imageUrl: image9,deduction: rowDetails['Vibrator'] },
        { label: 'Silent Button not working', imageUrl: image16 ,deduction: rowDetails['Silent Button']},
        { label: 'Battery in Service(<80%)', imageUrl: image10 ,deduction: rowDetails['Battery']},
        { label: 'Speaker Faulty', imageUrl: image11 ,deduction: rowDetails['speaker'] },
        { label: 'Charging Port not working', imageUrl: image12  ,deduction: rowDetails['Charge port']},
         { label: 'Audio Receiver not working', imageUrl: image14 ,deduction: rowDetails['Audio Receiver']},
         { label: 'Proximity sensor', imageUrl: image15 ,deduction: rowDetails['proximity sensor'] },
         { label: 'Microphone not working', imageUrl: image13 ,deduction: rowDetails['Microphone']},
      ],
    },
  ];

  const handleContinue = () => {
    const allCategoriesSelected = categories.every((category) => selectedOptions[category.id]);
    if (!allCategoriesSelected) {
      alert('Please select options for all categories.');
      return;
    }
    else {
      if (!FixedPrice) {
        const deductions = selectedOptions['functionalProblems'].map(option => option.deduction);
        console.log(deductions,"Funcdeductions")
       const totalDeduction = deductions.reduce((acc, deduction) => acc + deduction, 0);
        let newValue = currentValue + totalDeduction;
        dispatch(handlecurrentValue(newValue));
        
        const warrantyConditions = [
          "touch",
          "screenOriginal",
          "Screen cracked/ glass broken",
          "Average:-major scraches, major dents,panel crackes, loose screen gap",
          "Below Average:-Heavy scraches,Heavy dents and phone bent",
          "Phone Bent",
          "Phone Bent With Panel Crack",
          "Only Panel Broken"
        ];
        
        // Check if any warranty condition matches from the answers object
        const hasWarrantyIssue = warrantyConditions.some(condition => 
          Object.values(answers).includes(condition)
        );
        
        // Check if any of the answers related to warranty are "no"
        const isWarrantyDeclined = answers.warranty === "no" || answers.touch === "no" || answers.screenOriginal === "no";
        
        // Combine both checks: If any one of these conditions is met, trigger the warranty deduction
        const shouldDeductWarranty = isWarrantyDeclined || hasWarrantyIssue;
        
        if (shouldDeductWarranty) {
           newValue += rowDetails["> 11 months"];
          dispatch(handlecurrentValue(newValue));
          setshowWarrantyPage(false);
        }
      
      }
    setShowAccessories(true);

    }
    
    setShowFunctional(false);


  };

  const handleBack = () => {
    setShowFunctional(false);
    setShowDefects(true);
  };
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleFormData = (data) => {
    // ////console.log(data,"Functional")
     onFormData(data)
    
  };

     if(showScreenDefects){
      return <ScreenDefects
      userLogDetails={userLogDetails}
      onFormData={handleFormData}
     
     />
    }

  return (
    <>
         
           <Grid container spacing={2} sx={{ mt: 4 }}> 
      {showFunctional && (
       <>
        <Button
                                      variant="outlined"
                                      color="primary"
                                      onClick={()=> {
                                        setShowFunctional(false);
                                        setshowScreenDefects(true);
                                      }}
                                      className="go-back-button"
                              >
                              <ArrowBackIcon className="go-back-icon" sx={{ mr: 1 }} />
                              <Typography variant="body1" className="go-back-text" sx={{ fontWeight: 'bold' }}>
                              Go Back
                              </Typography>
                              </Button>
        <Grid container >
 <Grid item xs={12} sm={12} md={7}>          
         <Box
                                                     sx={{
                                                       // position: isMobile ? 'fixed' : 'static',
                                                       // bottom: isMobile ? 0 : 'auto',
                                                       // height: isMobile ? 'calc(100vh - 70px)' : 'auto', // Set height for mobile screens
                                                       // overflowY: isMobile ? 'auto' : 'initial', // Allow scrolling for mobile
                                                       // // paddingBottom: { xs: '80px', md: '0' },
                                                       // width: '100%',
                                                       p: 1,
                                                          // Hide scrollbar (cross-browser solution)
                                                   // '&::-webkit-scrollbar': {
                                                   //   display: 'none', // Hide scrollbar for WebKit browsers
                                                   // },
                                                   // '&::-webkit-scrollbar-thumb': {
                                                   //   backgroundColor: 'transparent', // Ensure it's completely transparent
                                                   // },
                                                   // '&::-webkit-scrollbar-track': {
                                                   //   backgroundColor: 'transparent', // Hide the scrollbar track
                                                   // },
                                                   // scrollbarWidth: 'none', // Hide scrollbar for Firefox
                                                 }}
                                                   >
                 
  
           {categories.map((category) => (
             
              <React.Fragment key={category.id}>
                
                 <Typography
                  variant="h5"
                  className="conditionsheading"
                  sx={{
                    marginBottom: 2,
                    fontWeight: 'bold',
                    color: '#333',
                    textAlign: 'center',
                  }}
                >                  
                {category.title}
                </Typography>
                <Typography
                  variant="h6"
                  className="conditionssubheading"
                  sx={{
                    marginBottom: 2,
                    fontWeight: 'bold',
                    color: '#333',
                    textAlign: 'center',
                  }}
                >                  
                {category.subtitle}
                </Typography>
                <Grid container spacing={2}>
                  {category.options.map((option, index) => (
                    <Grid item xs={6} md={4} key={index}>
      
                      <Box
                        // fullWidth
                        // variant={selectedOptions[category.id]?.some(selectedOption => selectedOption.label === option.label) ? '' : 'outlined'}
                        onClick={() => handleSelection(category.id, option)}
                        sx={{
                          border: selectedOptions[category.id]?.some(selectedOption => selectedOption.label === option.label)
                            ? '2px solid red' 
                            : '1px solid #ddd',
                          display: 'flex',
                          cursor: 'pointer',
                          borderRadius:"8px",
                          // width:"100%",
                          flexDirection: 'column',
                          boxShadow: selectedOptions[category.id]?.some(selectedOption => selectedOption.label === option.label) ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none',

                          alignItems: 'center',
                          textAlign: 'center',
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                          },
                          overflow: 'hidden',

                          // p: 2,
                          height: '200px', // Set a fixed height for uniformity

                        
                        }}
                      >
                        <img 
                        src={option.imageUrl} 
                        alt={option.label}
                        style={{
                          width: '100%',
                          height: '100px', // Adjust height to balance the overall layout
                          objectFit: 'contain',
                          borderBottom: '1px solid #ddd',
                          padding:"10%",
                        }}
                           />

<Typography
          variant="subtitle1"
          sx={{
            padding: '12px',
            fontSize: '10px',
            fontWeight: 'bold',
            color: selectedOptions[category.id]?.some(selectedOption => selectedOption.label === option.label) ? 'red' : '#333',
            flexGrow: 1, // Allows text to take up space within the box
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >   {option.label}
        
        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </React.Fragment>
            ))}

            </Box>
           
            <Box
           sx={{
             position: isMobile ? 'fixed' : 'static',
             bottom: isMobile ? 0 : 'auto',
             left: 0,
             width: '100%',
             p: 1,
             backgroundColor: isMobile ? '#fff' : 'transparent',
             boxShadow: isMobile ? '0px -2px 10px rgba(0, 0, 0, 0.1)' : 'none',
           }}
         >

<Button
variant="contained"
sx={{
backgroundColor: '#4BCEBD',
color: 'white',
padding: isMobile ? '8px 16px' : '12px 24px',
fontSize: isMobile ? '0.9rem' : '1.2rem',
fontSize: isMobile ? '0.9rem' : '1.2rem',
fontWeight: 'bold',
borderRadius: '10px',
width: '100%',
'&:hover': {
backgroundColor: '#4BCEBD',
},
}}
onClick={handleContinue}
endIcon={<ArrowForwardIcon />}
>
Continue
</Button>
         </Box>
               
                       </Grid>
          {!isMobile && (  
          <Grid item xs={12} md={5}>
            <DeviceEvaluation 
            // selectedModel={selectedModel}
            //  deviceAnswers={{ ...answers, ...selectedOptions }}
            //    formattedVariant={formattedVariant} 
            //    answers={answers}
                />
          </Grid>
          )}
        </Grid>
        </>
      )}

   </Grid>
            


      {showAccessories && (
        
          <Accessories  
              showWarrantyPage={showWarrantyPage1}
               deviceAnswers={{ ...answers, ...selectedOptions }}
                ScreenselectedOptions={ScreenselectedOptions}
                DefectsselectedOptions={DefectsselectedOptions}
                 functionalSelectedOptions={selectedOptions}
                 userLogDetails={userLogDetails}
                 onFormData={handleFormData}
                 />
        
      )}
 
{!isMobile && (
      <>
    <FAQ  />
<Footer />
</>
)}
    </>
  );
};

export default Functional;
