import React,{useEffect} from 'react';
import { CssBaseline, Container } from '@mui/material';
import Routes from './routes';
import './App.css'
import StickyIcons from './components/screens/StickyIcons'; // Import the StickyIcons component
// import {handleIsLogged} from "../src/redux/WrapperSlice"
// import { useDispatch } from 'react-redux';

const App = () => {
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   const storedIsLogged = JSON.parse(localStorage.getItem('isLogged'));
  //   if (storedIsLogged) {
  //     dispatch(handleIsLogged(storedIsLogged));
  //   }
  // }, [dispatch]);
  return (
    <>
      <CssBaseline />
      <Container maxWidth={false}>
        <Routes />
        <StickyIcons />
      </Container>
      {/* Add StickyIcons here */}

    </>
  );
};

export default App;
