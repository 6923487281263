import React, { useState,useEffect } from 'react';
import {
  Container,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Grid,
  Card,
  Snackbar,
  CardContent,
  Button,
  useMediaQuery
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Accessories from './Accessories';
import CustomerLogin from './CustomerLogin'
import SellNow from './SellNow';
import MuiAlert from '@mui/material/Alert';
import Footer from '../commonForms/Footer';
import FAQ from '../commonForms/FAQ';
import {useSelector,useDispatch} from 'react-redux';
 import { handleselectedWarranty,handlecurrentValue} from '../../redux/WrapperSlice';
 import { useNavigate, useParams,useLocation} from 'react-router-dom';

const MobileAgeSelector = ({
  deviceAnswers,
  userLogDetails,
  onFormData,
   
}) => {
    const navigate = useNavigate();
 const dispatch = useDispatch();
const { productType,brand,model,variant,step1,step2,step3,step4,step5,step6} = useParams();
const warrantyStatus = useSelector((state) => state.wrapper.warrantyStatus);
const [currentPage, setCurrentPage] = useState(warrantyStatus ? 'mobileAgeSelector' : 'loginSignup' );
 let currentValue = useSelector((state) => state.wrapper.currentValue);
 let reduxselectedWarranty = useSelector((state) => state.wrapper.selectedWarranty);
 console.log(reduxselectedWarranty,"reduxselectedWarranty")
  const [selectedWarranty, setSelectedWarranty] = useState('');
  const selectedModel = useSelector((state) => state.wrapper.selectedModel);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [alertOpen, setAlertOpen] = useState(false);
const answers =  useSelector((state) => state.wrapper.answers);
const formattedVariant = useSelector((state) => state.wrapper.formattedVariant);
const FixedPrice = useSelector((state) => state.wrapper.FixedPrice);
const city = useSelector((state) => state.wrapper.city);
const rowDetails = useSelector((state) => state.wrapper.rowDetails);
 const DefectsselectedOptions = useSelector((state) => state.wrapper.DefectsselectedOptions);
const ScreenselectedOptions = useSelector((state) => state.wrapper.ScreenselectedOptions);
const functionalSelectedOptions = useSelector((state) => state.wrapper.functionalSelectedOptions);
const accSelectedOptions =  useSelector((state) => state.wrapper.accSelectedOptions);
const  userDetails = useSelector((state) => state.wrapper.userDetails);
    useEffect(() => {
      navigate(`/sell/${productType}/${brand}/${model}/${variant}/${step1}/${step2}/${step3}/${step4}/${step5}/${step6}/warranty`, { replace: true });
            
          }, []);

            useEffect(() => {
                      if (reduxselectedWarranty) {
                        setSelectedWarranty(reduxselectedWarranty); // Set previously selected values
                      }
                    }, []); 
  
  const handleFormData = (data) => {
  onFormData(data)
    
  };


  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  const handleWarrantySelection = (event) => {
    const value = event.target.value;
    setSelectedWarranty(value);
    dispatch(handleselectedWarranty(value));
    let deduction = 0;
    if (value === 'below3months') {
      deduction = rowDetails["<3 months"];
    } else if (value === '3to6months') {
      deduction = rowDetails["3 -6 months"];
    } else if (value === '6to11months') {
      deduction = rowDetails["6-11 month"];
    } else if (value === 'above11months') {
      deduction = rowDetails["> 11 months"];
    }
   let newValue = currentValue + deduction;
   console.log(newValue,"Warranty")
   dispatch(handlecurrentValue(newValue));

  };

  const handleContinue = () => {
    if(!selectedWarranty){
      setAlertOpen(true);
      return;
    }
    setCurrentPage('loginSignup');

  };

  

  if (currentPage === 'loginSignup' && (!userDetails?.name)) {
    return <CustomerLogin 
      deviceAnswers={deviceAnswers} 
       userLogDetails={userLogDetails}
        onFormData={handleFormData}
        />;
     
   }

   if (currentPage === 'loginSignup' &&(userDetails?.name) ) {
    return <SellNow 
      selectedWarranty={selectedWarranty}
        deviceAnswers={deviceAnswers} 
        userLogDetails={userLogDetails} 
        onFormData={handleFormData}
        />;
     
   }
   

 if(currentPage === "Accessories"){
  return <Accessories
    selectedWarranty={selectedWarranty}
        deviceAnswers={deviceAnswers} 
        userLogDetails={userLogDetails} 
        onFormData={handleFormData}
        
  
  />
 }

  return (
   <>
    <Button
    variant="outlined"
    color="primary"
    onClick={()=> {
      setCurrentPage("Accessories");
    }}
    className="go-back-button"
>
<ArrowBackIcon className="go-back-icon" sx={{ mr: 1 }} />
<Typography variant="body1" className="go-back-text" sx={{ fontWeight: 'bold' }}>
Go Back
</Typography>
</Button>

    <Grid container spacing={2} mt={"4"}>
    {currentPage === "mobileAgeSelector" && (
      
    
        <Box sx={{ mt: 4 }}>
          {/* <Card sx={{ boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)', borderRadius: '12px', p: 3 }}> */}
          {/* <CardContent> */}
              <Typography variant="h5" align="center" gutterBottom>
                What is your mobile age?
              </Typography>
              <Typography variant="subtitle1" align="center" color="textSecondary" gutterBottom>
                (Because you chose your device is under brand's warranty)
              </Typography>

                  <Box
                                                              sx={{
                                                                // position: isMobile ? 'fixed' : 'static',
                                                                // bottom: isMobile ? 0 : 'auto',
                                                                // height: isMobile ? 'calc(100vh - 70px)' : 'auto', // Set height for mobile screens
                                                                // overflowY: isMobile ? 'auto' : 'initial', // Allow scrolling for mobile
                                                                // // paddingBottom: { xs: '80px', md: '0' },
                                                                // width: '100%',
                                                                p: 3,
                                                                   // Hide scrollbar (cross-browser solution)
                                                            // '&::-webkit-scrollbar': {
                                                            //   display: 'none', // Hide scrollbar for WebKit browsers
                                                            // },
                                                            // '&::-webkit-scrollbar-thumb': {
                                                            //   backgroundColor: 'transparent', // Ensure it's completely transparent
                                                            // },
                                                            // '&::-webkit-scrollbar-track': {
                                                            //   backgroundColor: 'transparent', // Hide the scrollbar track
                                                            // },
                                                            // scrollbarWidth: 'none', // Hide scrollbar for Firefox
                                                          }}
                                                            >
                          
              <RadioGroup value={selectedWarranty} onChange={handleWarrantySelection}>
      <Grid container spacing={2}>
        {[
          { value: 'below3months', label: 'Below 3 months', subtitle: 'Valid bill mandatory' },
          { value: '3to6months', label: '3 months - 6 months', subtitle: 'Valid bill mandatory' },
          { value: '6to11months', label: '6 months - 11 months', subtitle: 'Valid bill mandatory' },
          { value: 'above11months', label: 'Above 11 months', subtitle: '' },
        ].map((option) => (
          <Grid item key={option.value} xs={12}>
            <FormControlLabel
              value={option.value}
              control={
                <Radio
                  sx={{
                    '&.Mui-checked': {
                      color: '#4BCEBD',
                    },
                  }}
                />
              }
              label={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column', // Align items vertically
                    alignItems: 'flex-start', // Align content to the left
                    '&:hover': {
                      backgroundColor: '#f5f5f5',
                      borderRadius: '8px',
                      transition: 'background-color 0.3s ease-in-out',
                    },
                    p: 1,
                    borderRadius: '8px',
                  }}
                >
                  <Typography variant="body1">{option.label}</Typography>
                  {option.subtitle && (
                    <Typography variant="caption" color="textSecondary">
                      {option.subtitle}
                    </Typography>
                  )}
                </Box>
              }
              sx={{
                '& .MuiFormControlLabel-label': {
                  display: 'flex',
                  flexDirection: 'column', // Ensure vertical alignment
                  alignItems: 'flex-start',
                },
                '& .MuiFormControlLabel-root': {
                  display: 'block',
                  margin: 0,
                },
              }}
            />
          </Grid>
        ))}
      </Grid>
    </RadioGroup>
    </Box>


    <Box
           sx={{
             position: isMobile ? 'fixed' : 'static',
             bottom: isMobile ? 0 : 'auto',
             left: 0,
             width: '100%',
             p: 1,
             backgroundColor: isMobile ? '#fff' : 'transparent',
             boxShadow: isMobile ? '0px -2px 10px rgba(0, 0, 0, 0.1)' : 'none',
           }}
         >

<Button
variant="contained"
sx={{
backgroundColor: '#4BCEBD',
color: 'white',
padding: isMobile ? '8px 16px' : '12px 24px',
fontSize: isMobile ? '0.9rem' : '1.2rem',
fontSize: isMobile ? '0.9rem' : '1.2rem',
fontWeight: 'bold',
borderRadius: '10px',
width: '100%',
'&:hover': {
backgroundColor: '#4BCEBD',
},
}}
onClick={handleContinue}
endIcon={<ArrowForwardIcon />}
>
Continue
</Button>


    <Snackbar 
            open={alertOpen} 
            autoHideDuration={6000} 
            onClose={handleAlertClose}
            >
              <MuiAlert 
              elevation={6}
               variant="filled"
                onClose={handleAlertClose} 
                severity="error"
                
                >
                Please select the Warranty.
              </MuiAlert>
            </Snackbar>
         </Box>
         
        </Box>
       
      )}

{/* {!isMobile && (
      <>
    <FAQ  />
<Footer />
</>
)} */}
   </Grid>
   </>
   
  );
};

export default MobileAgeSelector;
