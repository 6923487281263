import React, { useState, useEffect } from 'react';
import { Box, Typography,
   Grid,
    Button,
     Paper,
      Container,
       Snackbar,
        Alert as MuiAlert,
        useMediaQuery
       } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Charger from '../../assets/images/charger.png'
import Box1 from '../../assets/images/Box.png'
import Bill from '../../assets/images/Bill.jpeg'
import { useDispatch, useSelector } from 'react-redux';
import DeviceEvaluation from '../commonForms/DeviceEvaluation'; // Update the import path as necessary
import Warranty from './Warranty';
import CustomerLogin from './CustomerLogin'
import SellNow from './SellNow';
import Footer from '../commonForms/Footer';
import FAQ from '../commonForms/FAQ';
import {handleaccSelectedOptions,handlecurrentValue} from "../../redux/WrapperSlice";
 
import { useNavigate, useParams,useLocation} from 'react-router-dom';
import Functional from './Functional';

const Accessories = ({ 
    showWarrantyPage,
    userLogDetails,
    onFormData
  }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productType,brand,model,variant,step1,step2,step3,step4,step5} = useParams();
  const [selectedAccessories, setSelectedAccessories] = useState([]);
  const [showEvaluation, setShowEvaluation] = useState(true);
  const[ShowFunctional,setShowFunctional] = useState(false);
  const [showWarranty,setshowWarranty] = useState(false);
   const  reduxaccselected = useSelector((state) => state.wrapper.accSelectedOptions);
      console.log(reduxaccselected,"accSelectedOptions")
  let currentValue = useSelector((state) => state.wrapper.currentValue);
  console.log(currentValue,"acc")
  const isMobile = useMediaQuery('(max-width:600px)');
  const selectedModel = useSelector((state) => state.wrapper.selectedModel);
  const answers =  useSelector((state) => state.wrapper.answers);
  const formattedVariant = useSelector((state) => state.wrapper.formattedVariant);
  const FixedPrice = useSelector((state) => state.wrapper.FixedPrice);
  const city = useSelector((state) => state.wrapper.city);
  const rowDetails = useSelector((state) => state.wrapper.rowDetails);
  const warrantyStatus = useSelector((state) => state.wrapper.warrantyStatus);
  const DefectsselectedOptions = useSelector((state) => state.wrapper.DefectsselectedOptions);
  const ScreenselectedOptions = useSelector((state) => state.wrapper.ScreenselectedOptions);
  const functionalSelectedOptions = useSelector((state) => state.wrapper.functionalSelectedOptions);
  const  userDetails = useSelector((state) => state.wrapper.userDetails);
  
   useEffect(() => {
    navigate(`/sell/${productType}/${brand}/${model}/${variant}/${step1}/${step2}/${step3}/${step4}/${step5}/accessories`, { replace: true });
          
        }, []);

    useEffect(() => {
            if (reduxaccselected) {
              setSelectedAccessories(reduxaccselected); // Set previously selected values
            }
          }, []); 

  useEffect(() => {
   }, [selectedAccessories]);
  const handleSelection = (accessory) => {
    setSelectedAccessories((prev) => {
      const isSelected = prev.some((item) => item.label === accessory.label);
      if (isSelected) {
        const updatedAccessories = prev.filter((item) => item.label !== accessory.label);
        console.log("Deselected Accessory:", accessory); // 🔍 Log deselected accessory
        const resetValue = (currentValue - accessory?.deduction)
          dispatch(handlecurrentValue(resetValue));

        dispatch(handleaccSelectedOptions(updatedAccessories)); // ✅ Correctly dispatch the updated array
  
        return updatedAccessories;
      } else {
        const updatedAccessories = [...prev, accessory];
  
        dispatch(handleaccSelectedOptions(updatedAccessories)); // ✅ Append new selection while keeping previous selections
        
        return updatedAccessories;
      }
    });
  };

  const handleFormData = (data) => {
      onFormData(data)
    
  };

  const accessories = [
    { label: 'Original Charger of Device', imageUrl: Charger ,deduction: rowDetails['Charger']},
    { label: 'Box with same IMEI', imageUrl: Box1 ,deduction: rowDetails['Box'] },
    { label: 'Bill Of the Device', imageUrl: Bill ,deduction: rowDetails['Bill']},
  ];

  const handleContinue = () => {
    if (!FixedPrice) {
      let deduction = 0;
      const notSelectedAccessories = accessories.filter((acc) => !selectedAccessories.some(selected => selected.label === acc.label));
 
      if (selectedAccessories.length === 0) {
        // 3 not selected => Deduct the maximum (least negative) price from the list
        deduction = Math.min(...accessories.map((acc) => acc.deduction));
      } else if (selectedAccessories.length === 1) {
        // 1 selected and 2 not selected => Deduct the maximum (least negative) price from the not selected list
        deduction = Math.min(...notSelectedAccessories.map((acc) => acc.deduction));
       } else if (selectedAccessories.length === 2) {
        // 2 selected and 1 not selected => Deduct the price of the not selected accessory
        deduction = notSelectedAccessories[0].deduction;
      } // No deduction if all 3 are selected


       const updatedCurrentValue = currentValue + deduction;
      console.log(updatedCurrentValue,"acces")

        dispatch(handlecurrentValue(updatedCurrentValue));
    }
      setShowEvaluation(false);  
      setshowWarranty(true);
    
    

   

  };


 

 
  if(ShowFunctional){
    return <Functional
         deviceAnswers={{ ...answers, accessories: selectedAccessories }} 
         ScreenselectedOptions={ScreenselectedOptions} 
         DefectsselectedOptions={DefectsselectedOptions} 
         functionalSelectedOptions={functionalSelectedOptions} 
    
    />
    }

  return (
    <>
      {showEvaluation && (
        <>
              <Button
                                              variant="outlined"
                                              color="primary"
                                              onClick={()=> {
                                                setShowEvaluation(false);
                                                setShowFunctional(true);

                                              }}
                                              className="go-back-button"
                                      >
                                      <ArrowBackIcon className="go-back-icon" sx={{ mr: 1 }} />
                                      <Typography variant="body1" className="go-back-text" sx={{ fontWeight: 'bold' }}>
                                      Go Back
                                      </Typography>
                                      </Button>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
           
            <Typography
                  variant="h5"
                  className="conditionsheading"
                  sx={{
                    marginBottom: 2,
                    fontWeight: 'bold',
                    color: '#333',
                    textAlign: 'center',
                  }}
                >
                  Do you have the following?
                  </Typography>
                  <Typography
                  variant="h6"
                  className="conditionssubheading"
                  sx={{
                    marginBottom: 2,
                    fontWeight: 'bold',
                    color: '#333',
                    textAlign: 'center',
                  }}
                >Please select accessories which are available
                </Typography>
          
           
              <Grid container spacing={4} justifyContent="center" style={{marginBottom:"10%"}}>
                {accessories.map((accessory, index) => (
                  <Grid item xs={6} sm={6} md={4} key={index}>
                       <Box
                      onClick={() => handleSelection(accessory)}
                                  sx={{
                      cursor: 'pointer',
                      border: selectedAccessories.some((item) => item.label === accessory.label)  ? '2px solid #4BCEBD' : '1px solid #ddd',
                      borderRadius: '12px',
                      overflow: 'hidden',
                      textAlign: 'center',
                      boxShadow:selectedAccessories.some((item) => item.label === accessory.label) ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                      },
                      height: '200px', // Set a fixed height for uniformity
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                 >           
                
                   
                      <img
                        src={accessory.imageUrl}
                        alt={accessory.label}
                         style={{
                        width: '100%',
                        height: '120px', // Adjust height to balance the overall layout
                        objectFit: 'contain',
                        borderBottom: '1px solid #ddd',
                        padding:"7%"
                      }}
                      />
                        <Typography
          variant="subtitle1"
          sx={{
            padding: '12px',
            fontSize: '14px',
            fontWeight: 'bold',
            color: selectedAccessories.some((item) => item.label === accessory.label)  ? 'green' : '#333',
            flexGrow: 1, // Allows text to take up space within the box
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        > {accessory.label}
        
        </Typography>
        </Box>
                  </Grid>
                ))}
              </Grid>
          
             
        
              <Box
           sx={{
             position: isMobile ? 'fixed' : 'static',
             bottom: isMobile ? 0 : 'auto',
             left: 0,
             width: '100%',
             p: 1,
             backgroundColor: isMobile ? '#fff' : 'transparent',
             boxShadow: isMobile ? '0px -2px 10px rgba(0, 0, 0, 0.1)' : 'none',
           }}
         >

<Button
variant="contained"
sx={{
backgroundColor: '#4BCEBD',
color: 'white',
padding: isMobile ? '8px 16px' : '12px 24px',
fontSize: isMobile ? '0.9rem' : '1.2rem',
fontSize: isMobile ? '0.9rem' : '1.2rem',
fontWeight: 'bold',
borderRadius: '10px',
width: '100%',
'&:hover': {
backgroundColor: '#4BCEBD',
},
}}
onClick={handleContinue}
endIcon={<ArrowForwardIcon />}
>
Continue
</Button>
         </Box>
          
            {/* </Paper> */}
          </Grid>


          {/* ---Mobile----- */}
          {!isMobile && (  
          <Grid item xs={12} md={5}>
            <DeviceEvaluation />
          </Grid>
          )}
        </Grid>
        </>
      )}


{/* {showWarranty && (
  ((!userDetails?.name) && (!warrantyStatus || !showWarrantyPage)) ? (
    <CustomerLogin 
      deviceAnswers={{ ...answers, accessories: selectedAccessories }} 
      ScreenselectedOptions={ScreenselectedOptions} 
      DefectsselectedOptions={DefectsselectedOptions} 
      functionalSelectedOptions={functionalSelectedOptions} 
      accSelectedOptions={selectedAccessories} 
      userLogDetails={userLogDetails}
      onFormData={handleFormData}

    />
  ) :
  (!warrantyStatus || !showWarrantyPage || userDetails?.name)&&
  <SellNow 
  deviceAnswers={{ ...answers, accessories: selectedAccessories }} 
  ScreenselectedOptions={ScreenselectedOptions} 
  DefectsselectedOptions={DefectsselectedOptions} 
  functionalSelectedOptions={functionalSelectedOptions} 
  accSelectedOptions={selectedAccessories} 
  userLogDetails={userLogDetails}
  onFormData={handleFormData}
/>
  )} */}
  
  {
  showWarranty && 
    
      <Warranty 
        deviceAnswers={{ ...answers, accessories: selectedAccessories }} 
        ScreenselectedOptions={ScreenselectedOptions} 
        DefectsselectedOptions={DefectsselectedOptions} 
        functionalSelectedOptions={functionalSelectedOptions} 
        accSelectedOptions={selectedAccessories} 
        userLogDetails={userLogDetails}
        onFormData={handleFormData}
      />
   
  }

{/* {!isMobile && (
      <>
    <FAQ  />
<Footer />
</>
)} */}
  



      
    </>
  );
};

export default Accessories;
