import React,{useState,useEffect} from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Select,
  Divider,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  IconButton,
  Grid,
  Dialog, DialogTitle, DialogContent, DialogActions,
  FormControl,FormLabel, InputLabel, 
  FormControlLabel,
  TextField,
  Radio, RadioGroup,
  FormHelperText,
  Alert,
  MenuItem
} from "@mui/material";
import { format } from 'date-fns';
import ClearIcon from "@mui/icons-material/Clear"
import axios from 'axios';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { addDays, isBefore, startOfToday, setHours, setMinutes } from 'date-fns';
import { config } from '../../config';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate,useParams } from "react-router-dom";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CircleIcon from "@mui/icons-material/Circle"; // Optional for default steps
import { handleCancelDialog } from "../../redux/WrapperSlice";
import { useDispatch, useSelector } from 'react-redux';
import { LocalizationProvider, DatePicker} from '@mui/x-date-pickers';
import CircularProgress from '@mui/material/CircularProgress';

const OrderDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [rescheduleDialogOpen, setrescheduleDialogOpen] = useState(false); // State for report dialog
  const [selectedDate, setSelectedDate] = useState(null);
  const [errors, setErrors] = useState({});
  const [pickupTimeOption, setPickupTimeOption] = useState('10am to 2pm'); // State for pickup time option
  const [dates, setDates] = useState([]);
  const navigate = useNavigate();
  const order = location.state?.order;
  //console.log(order.ID,"IDDD")
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'
  const[loading,setLoading] = useState(false);
  const[FinalAmount,setFinalAmount] = useState(0);


  const [formData, setFormData] = useState({
    paymentOption:order.PaymentOption,
    city:order.city,
    address:"",
    pickupDate: order["Pickup Date"],
    pickupTime: order.PickupTime,
    pickupTimeOption: '10am to 2pm', // Include pickup time option in formData
  })


  useEffect(() => {
      const today = startOfToday();
      const nextDays = [];
      for (let i = 0; i < 4; i++) {
        const nextDay = addDays(today, i);
        if (i === 0 && new Date().getHours() >= 14) {
          continue;
        }
        nextDays.push(nextDay);
      }
      setDates(nextDays);

      handleShowFinalAmount();
    }, []);

     const disablePastDates = (date) => {
        return isBefore(date, startOfToday());
      };

    
  
     
 
const handleDateSelect = (date) => {
  if (!date) return;

  const localDateFormatted = format(date, 'yyyy-MM-dd');

  setSelectedDate(date);
  setFormData((prevData) => ({
    ...prevData,
    pickupDate: localDateFormatted,
  }));
};
  
   
    const handlePickupTimeOptionChange = (e) => {
      const value = e.target.value;
      setPickupTimeOption(value);
      setFormData(prevData => ({
        ...prevData,
        pickupTimeOption: value,
      }));
    };
  
  
    const handleChange = (e) => {
      //console.log(e.target.value,"e.target.value")
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    };
 
  
  // Mapping backend status to stepper status index
  const statusMap = {
    "Pickup Requested": 0,
    "In Progress": 1,
    "Pickup Completed": 2,
    "Pickup Failed": 2, // Treat Failed as the same step as Completed
   
  };
  
  const steps = ["Pickup Requested", "In Progress", `Pickup ${order.Status === "Failed" ? "Failed" : "Completed"}`];
  
  const statusText =
    order.Status === "Completed"
      ? "Pickup Completed"
      : order.Status === "Failed"
      ? "Pickup Failed"
      : order.Status;
  
  const statusIndex = statusMap[statusText] ?? 0;
  //console.log(statusIndex,"statusIndex")
  const statusColor = statusText === "Pickup Failed" ? "error" : "success";


  const CustomStepConnector = (props) => (
    <StepConnector
      {...props}
      sx={{
        '& .MuiStepConnector-line': {
          borderColor: props.failed ? "green" : "green",
        },
      }}
    />
  );

  // Custom Step Icon to show check or cancel
const CustomStepIcon = (props) => {
  const { active, completed, icon } = props;
  const isFailed = statusText === "Pickup Failed" && icon === steps.length;

  if (isFailed) {
    return <CancelIcon color="error" />;
  }

  if (completed || active) {
    return <CheckCircleIcon color="success" />;
  }

  return <CircleIcon color="disabled" fontSize="small" />;
};




// RESCHEDULE API

 const  handleSubmit = async () => {
  //console.log(">>>>>")
 setLoading(true); 
  const newErrors = {};

    // //console.log(newErrors,"newErrors")
   
      try {
        const url = `${config.Api}userpickup/${order?.ID}`

      const method = 'POST';
      const response = await fetch(url, {
        method: method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

        if (response.ok) {
          setMessage('Details submitted successfully!');
          setMessageType('success');
          setFormData({
          paymentOption:"",
           pickupDate: null,
            pickupTime: null,
            pickupTimeOption: '10am to 2pm', // Reset to default value
           
          });
          navigate("/profile");
        } else {
          // setPickupDetails([]);
          throw new Error('Failed to submit details');
        }
      } catch (error) {
        setMessage('Error submitting details. Please try again.');
        setMessageType('error');
       }finally {
        setLoading(false); // Stop loading after processing
      }
   
    
  };


  
  const handleShowFinalAmount = async (id) => {
    // setLoadingStates((prev) => ({ ...prev, [id]: true }));
    setLoading(true);

    try {
      const response = await axios.post(`${config.Api}get-proof-details/${order?.ID}`);
       setFinalAmount(response.data[0].finalPrice);
       setLoading(false);
    } catch (error) {
      console.error('Error fetching final amount:', error);
      // alert('No Final amount Found for this');
    } finally {
      setLoading(false);
    }
};
  return (
    <>
    <Box sx={{ p: 1, backgroundColor: "#ffff", minHeight: "100vh" }}>
      {/* Back Button */}
      <Button startIcon={<ArrowBackIcon />} onClick={() => navigate("/my-orders")}>
        Back
      </Button>

      {/* Order Header */}
      <Typography variant="h6" fontWeight="bold" mt={2}>
        Device Details
      </Typography>

      {/* Order Card */}
      <Card sx={{ p: 2, borderRadius: 2 }}>

      <Box display="flex" alignItems="center" mb={1}>
              <IconButton color={statusColor}>
                {statusColor === "error" ? <CancelIcon /> : <CheckCircleIcon />}
              </IconButton>
              <Box ml={1}>
                <Typography fontWeight="bold">{statusText}</Typography>
                <Typography variant="body2" color="textSecondary">
                  Pickup Date: {order["Pickup Date"]}
                </Typography>
              </Box>
            </Box>
        <Box display="flex" mt={2} alignItems="center" style={{gap:"10%"}}>
          <img
           src={order.ImageURL}
          alt={order["Brand Name"]}
            width={80}
            height={80}
            style={{ borderRadius: 8 }}
          />
          <CardContent sx={{ flexGrow: 1, pl: 2 }}>
            <Typography variant="body2" fontWeight="bold"> {order["Brand Name"]} {order.Model}</Typography>
            <Typography  fontWeight="bold" color="textSecondary">Service Number:</Typography>
            <Typography fontWeight="bold" color="black">{order.ID}</Typography>
            <Typography  variant="body2" fontWeight="textSecondary">Order created on:</Typography>
            <Typography variant="body2" fontWeight="bold" color="black">
  {order?.orderCreatedOn ? format(new Date(order.orderCreatedOn), 'dd-MM-yyyy hh:mm a') : ''}
</Typography>            
<Typography variant="body1" sx={{ fontWeight: 'bold', color: 'red' }}>
{FinalAmount
        ? `₹ ${new Intl.NumberFormat("en-IN").format(FinalAmount)}`: `₹ ${new Intl.NumberFormat("en-IN").format(order.QuotedPrice)}`
       }</Typography>

{/* <Typography variant="h5" fontWeight="bold" color="error.main" mt={1}>  {order.amount
        ? `₹ ${new Intl.NumberFormat("en-IN").format(order.QuotedPrice)}`
        : `₹ ${new Intl.NumberFormat("en-IN").format(order.QuotedPrice > 0 ? order.QuotedPrice : order.QuotedPrice)}`}</Typography> */}
          </CardContent>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Order Status with Stepper */}
        <Typography fontWeight="bold" mb={1}>Order Status</Typography>
        <Stepper
    activeStep={statusIndex}
    orientation="vertical"
    connector={
      <CustomStepConnector failed={statusText === "Pickup Failed"} />
    }
  >
    {steps.map((label, stepIndex) => (
      <Step key={stepIndex} completed={stepIndex < statusIndex}>
        <StepLabel
          StepIconComponent={CustomStepIcon}
          sx={{
            color:
              stepIndex === statusIndex && statusText === "Pickup Failed"
                ? "error.main"
                : "success.main",
          }}
        >
          {label}
        </StepLabel>
      </Step>
    ))}
  </Stepper>

        <Divider sx={{ my: 2 }} />

        {/* Pickup Address */}
        <Typography fontWeight="bold">Pickup Address</Typography>
        <Typography variant="body2" color="textSecondary">{order.Address}</Typography>

        <Divider sx={{ my: 2 }} />
        {/* Pickup Date and Time */}
        <Typography fontWeight="bold">Pickup Date & Time</Typography>
        <Box display="flex" alignItems="center" gap={1}>
          <CalendarTodayIcon fontSize="small" color="action" />
          <Typography variant="body2" color="textSecondary">{order["Pickup Date"]}</Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={1} mt={1}>
          <AccessTimeIcon fontSize="small" color="action" />
          <Typography variant="body2" color="textSecondary">{order.PickupTime}</Typography>
        </Box>

        <Divider sx={{ my: 2 }} />
     
    
    
      </Card>
    </Box>
    <Divider sx={{ my: 2 }} />
    <Box sx={{ p: 2, backgroundColor: "#fff", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
  <Typography variant="h6" sx={{ fontWeight: "bold" }}>Price Summary</Typography>
  <Divider sx={{ my: 2 }} />

  <Grid container justifyContent="space-between">
    <Typography variant="body1">Base Price:</Typography>
    <Typography variant="body1" fontWeight="bold">
      {order.QuotedPrice
        ? `₹ ${new Intl.NumberFormat("en-IN").format(order.QuotedPrice)}`
        : `₹ ${new Intl.NumberFormat("en-IN").format(order.QuotedPrice > 0 ? order.QuotedPrice : order.QuotedPrice)}`}
    </Typography>
  </Grid>
  <Divider sx={{ my: 2 }} />

  <Grid container justifyContent="space-between">
    <Typography variant="body1">Pickup Charges:</Typography>
    <Typography variant="body1">
      Free <span style={{ textDecoration: "line-through" }}>₹ 100</span>
    </Typography>
  </Grid>
  <Divider sx={{ my: 2 }} />

  <Grid container justifyContent="space-between">
    <Typography variant="body1">Processing Fee:</Typography>
    <Typography variant="body1">₹0</Typography>
  </Grid>
  <Divider sx={{ my: 2 }} />

  <Grid container justifyContent="space-between">
    <Typography variant="body1" sx={{ fontWeight: "bold" }}>Total Quoted Amount:</Typography>
    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
      {order.QuotedPrice
        ? `₹ ${new Intl.NumberFormat("en-IN").format(order.QuotedPrice)}`
        : `₹ ${new Intl.NumberFormat("en-IN").format(order.QuotedPrice > 0 ? order.QuotedPrice : order.QuotedPrice)}`}
    </Typography>
  </Grid>
  <Divider sx={{ my: 2 }} />

  <Grid container justifyContent="space-between">
    <Typography variant="body1" sx={{ fontWeight: "bold" }}>Final Sell Amount:</Typography>
    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'red' }}>
{FinalAmount
        ? `₹ ${new Intl.NumberFormat("en-IN").format(FinalAmount)}`:"pending"
       }</Typography>
  </Grid>



  <Divider sx={{ my: 2 }} />

  <Grid container justifyContent="space-between">
  <FormControl fullWidth>
            <InputLabel>Change Payment Mode</InputLabel>
            <Select 
              value={formData.paymentOption ? formData.paymentOption : order?.PaymentOption} 
              onChange={(e) => setFormData({...formData,paymentOption:e.target.value})}
              endAdornment={
                formData.paymentOption && (
                  <IconButton size="small" onClick={() => setFormData({...formData,paymentOption:""})}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                )
              }
            >
              <MenuItem value="Paytm">Paytm</MenuItem>
              <MenuItem value="Google Pay">Google Pay</MenuItem>
              <MenuItem value="PhonePe">PhonePe</MenuItem>
              <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
            </Select>
          </FormControl>


          <Button 
            variant="contained" 
            color="primary" 
            fullWidth 
            onClick={handleSubmit} 
            // disabled={!paymentMethod}
            style={{ marginTop: "10px" }}
          >
            Change
          </Button>
  </Grid>

  {/* <Grid container justifyContent="space-between">
  <Typography variant="body1" sx={{ fontWeight: "bold" }}>Current Payment Method: {order?.PaymentOption}</Typography>
  </Grid> */}
</Box>
{order.Status !== "Failed" && order.Status !== "Completed" && (
  <Box display="flex" justifyContent="space-between" mt={2}>
          <Button 
          onClick={() => {
            dispatch(handleCancelDialog(true))
            navigate(`/cancel-order`, { state: { uniqueId1: order?.ID } });
          }}
            variant="outlined" 
            color="error" 
            sx={{ borderRadius: 2, fontWeight: "bold", px: 3 }}
          >

            Cancel Order
          </Button>
          <Button 
          onClick={() => setrescheduleDialogOpen(true)}
            variant="contained" 
            color="primary" 
            sx={{ borderRadius: 2, fontWeight: "bold", px: 3 }}
          >
            Reschedule
          </Button>
        </Box>
)}

  <Dialog open={rescheduleDialogOpen} onClose={() => setrescheduleDialogOpen(false)} maxWidth="sm" fullWidth>
      {message && (
                <Alert severity={messageType} sx={{ mb: 2 }}>
                  {message}
                </Alert>
              )}
  <Grid item xs={12}>
            <FormControl fullWidth error={!!errors.pickupDate}>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Pickup Date"
                  value={formData.pickupDate}
                  onChange={handleDateSelect}
                  minDate={new Date()}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  shouldDisableDate={disablePastDates}

                />
              </LocalizationProvider>
              <FormHelperText>{errors.pickupDate}</FormHelperText>
              </FormControl>

              </Grid>
         
              {selectedDate && (
              <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Pickup Time</FormLabel>
                <RadioGroup row value={pickupTimeOption} onChange={handlePickupTimeOptionChange}>
                  <FormControlLabel value="10am to 2pm" control={<Radio />} label="10am to 2pm" />
                  <FormControlLabel value="2pm to 6pm" control={<Radio />} label="2pm to 6pm" />
                </RadioGroup>
              </FormControl>
            </Grid>
            )}

               <Grid item xs={12}>
                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
                              {loading ? <CircularProgress size={24} /> : 'Submit'}
                            </Button>
                          </Box>
                        </Grid>
  </Dialog>
  </>
  );
};

export default OrderDetails;
