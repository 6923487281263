import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Container, Typography, Box, Button, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
  root: {
    padding: "4%",
    backgroundColor: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: "1px",
    maxWidth: 900,
    margin: 'auto',
    marginTop:"4%",
  },
  title: {
    marginBottom: "3%",
    color: '#06A89D',
  },
  section: {
    marginBottom: "3%",
  },
  listItem: {
    marginBottom: "1%",
  },
}));

const TermsAndConditions = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate('/');
  };

  return (
    
      <Container className={classes.root}>
        <IconButton onClick={handleBackClick} className={classes.backButton}>
         <Button className='go-back-button' startIcon={<ArrowBackIcon className='go-back-icon'/>}>
         <Typography variant="body1" className='go-back-text' sx={{ fontWeight: 'bold' }} color="#4BCEBD">
                Go back
               </Typography>
         </Button> 
        </IconButton>
      <Typography variant="h4" className={classes.title} gutterBottom>
      Privacy Policy
      </Typography>

      <Box className={classes.section}>

    
        <Typography variant="p" gutterBottom>
       <strong>What is this privacy notice for?</strong> <br/>
        </Typography>
        <Typography variant="body1">


        We may handle your personal data in connection with your use of the Platform. This privacy notice (together with our Terms and Conditions) set out, for the Platform, our collection and sharing practices, the uses to which personal data is put, the ways in which we protect it in accordance with the data protection laws and your privacy rights. Please read it carefully.
This Statement applies to Personal Data processed by Cashonspot when you:
– Visit this website (www.Cashonspot.in) and/or any other CASHONSPOT website(s) which reference or link to this Statement (collectively, “Website”).
– Use, download, access, as applicable, any of our various internet-based offerings, including mobile platforms, software, or applications (collectively, “Services”).
– Visit CASHONSPOT’s branded social media sites.
– Receive communications from us, including emails, phone calls, or other electronic messages; or Data we collect.
We collect some information directly from you (for example, via forms you complete or products or Services you access, download, or otherwise obtain). Such information is generally limited to:
– Name, Contact details, Email ID, IMEI, Device Details
– Your communications with CASHONSPOT personnel.
– Content you post on our social media sites.
– Information you provide on the Website, such as online questionnaires, or feedback forms.
– Information you provide when you subscribe to sms services.
– Information you provide when you create your account, log-in credentials and information about your use of and preferences for the Services.
– Other information is received indirectly from you via use of the Services (for example, from observing your actions on the Website or to provision you with account access).

            </Typography>


            <Typography variant="p" gutterBottom>
       <strong>What is this privacy notice for?</strong> <br/> 
        </Typography>

        <Typography variant="p" gutterBottom>
          <strong>2. Such information is generally limited to:</strong>

        </Typography>
        <Typography variant="body1">
            Information regarding usage of the Services or Website via web logs collected automatically and passively using various technologies, which generally will not specifically identify you or other individuals. Examples may include IP addresses, Device details, browser types, domain names, and other anonymous statistical data regarding aggregate usage.
Lastly, we also collect Personal Data via other sources such as public records, publicly available sources or internet sites, vendors, data suppliers and service providers, commercially available marketing lists or registries, telephone directories, social network, news outlets and related media. Such Personal Data collected via these sources is limited to business contact information, such as names, contact information, job titles, IP addresses, and LinkedIn and other social media profiles.


</Typography>

<Typography variant="p" gutterBottom>
         <strong>2. Use of Personal Data</strong> 
</Typography>

        <Typography variant="body1">
        CASHONSPOT uses your Personal Data when: it is necessary to perform our obligations or exercise our contractual rights; we have a legitimate business interest to process such Personal Data; it is necessary to comply with applicable laws or regulations; we have your consent (where required under applicable law) to use your information (where we rely on your consent, you have the right to withdraw consent by contacting us as set forth below).
   </Typography>

       </Box>

      <Box className={classes.section}>
      <Typography variant="p" gutterBottom>
      <strong>Such legal reasons are identified for the following common instances:</strong>

        </Typography>

      
        <ul>
          <li className={classes.listItem}>
          <strong>Administering our Website and Providing Services:</strong> Personal Data is processed to perform our contractual obligations, where applicable. In instances where we have not entered into a contract with you, such processing is based on our legitimate interest to manage and promote our business, operate and administer the Website, provide and improve the Services, and to provide requested content (for example, when you request a quote on our website).
          </li>

          <li className={classes.listItem}>
          <strong>User Registration:</strong> In accordance with our contractual obligations or legitimate interest to provide Services, when you register for an account with CASHONSPOT, we process your Personal Data as needed to register and administer your account, provide technical and client support and training, verify your identity, and send important account, and Services information.
          </li>

          <li className={classes.listItem}>
          <strong>Support Requests:</strong>If you request that CASHONSPOT contacts you (for example, for any queries related to products and services), or if you contact us by means available on website, we process your Personal Data to the extent it is necessary for our legitimate interest in fulfilling your requests and communicating with you, or as is required by contractual obligations.
          </li>

          <li className={classes.listItem}>
          <strong>Compliance; Security and Functionality of Website and Services:
          </strong>
          For our legitimate interests in ensuring adherence to relevant terms, we process your Personal Data to review compliance with the applicable usage. Personal Data is also processed by tracking use of the Website and Services, to perform our contractual obligations, where applicable, to provide a secure and functional experience. In instances where we have not entered into a contract with you, such processing is based on our legitimate interest in promoting the safety and security of the Website, Services, systems and applications and in protecting our rights and the rights of others, whereby we reserve the right to monitor, investigate and report any attempts to breach the security of the Website or Services, verify accounts and activity, and enforce our terms and policies.          
          </li>
          <li className={classes.listItem}>
          <strong>Improving Website and Services; Developing Offerings:</strong>
          We process your Personal Data to analyze trends, usage, and interactions with the Website and Services to the extent it is necessary for our legitimate interest in improving the Website and Services, developing new offerings, and providing individuals with tailored content and to personalize your experience with our Services. In certain instances, we will process your Personal Data for such development by directly seeking your input. (for example, through feedback surveys).

          </li>

          <li className={classes.listItem}>
          <strong>
            Marketing Communications; Advertisement:
          </strong>
          It is our legitimate business interest to promote our Website and Services, grow our company, and advertise our offerings (whether via direct marketing or providing personalized content advertisements), and as such we will process your Personal Data by sending certain marketing information and other communications solely as necessary to reflect such legitimate interests, or, if applicable, to the extent you have provided your prior consent.

          </li>


          <li className={classes.listItem}>
          <strong>Payments:</strong>
          In the instances where you have provided financial information to us, CASHONSPOT will process your Personal Data to verify such information and to collect/process payments solely as needed to complete a transaction and perform our contractual obligations. Legal Obligations: We may be required to process Personal Data for legal and compliance reasons we believe to be necessary or appropriate under applicable law. Such processing may be required to: respond to requests from courts, law enforcement agencies, regulatory agencies, and other public and government authorities; enforce our terms of service or other terms and conditions; or protect our rights, privacy, safety, or property, or those of other persons. Tracking users’ use of the Platform
We use various tools, and collect various information, to assess how you use and interact with the Platform, including information about your visit, such as your IP address, details of your computer systems, device ID, browser detail and location, connections, or any information that we may collect through the use of cookies, web beacons/gifts, and Behavioral targeting/re-targeting. In most cases this data will be collected automatically. You can find more information about our use of this type of technology in our Cookies Policy.

          </li>

        
         
        </ul>
      </Box>

      <Box className={classes.section}>
        <Typography variant="p" gutterBottom>
        <strong>
        Disclosure of your Information
        CASHONSPOT may share Personal Data as follows:
        </strong>
</Typography>
        <ul>
          <li className={classes.listItem}>
            <Typography variant="body1">
            <strong>Contracted Service Providers.</strong> <br/>
            As necessary to provide the Services, conduct our business operations, or when we believe that applicable law permits or requires disclosure, we may share Personal Data with contracted service providers, including entities which provide for hosting and system administration, payment processing, analytics, marketing, data enrichment, or client support, to the extent necessary and in accordance with the legal bases set forth above. These service providers only receive Personal Data necessary to fulfil the services they provide to CASHONSPOT. Under no circumstances are such service providers permitted to use obtained Personal Data for any purpose other than to provide CASHONSPOT with the designated services.

</Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
            <strong>Your Employer:</strong> <br/> To the extent you are an authorized user of the Services or have otherwise registered for CASHONSPOT offerings using your corporate email address, we may share Personal Data with your employer to the extent this is necessary to provide you with access, to verify accounts and activity, investigate suspicious activity, or enforce our terms and policies.                </Typography>
          </li>

          <li className={classes.listItem}>
            <Typography variant="body1">
            <strong>Mergers or Acquisitions:</strong> <br/> Mergers or Acquisitions: In the event of a merger, acquisition, consolidation, change of control, corporate divestiture or dissolution where we sell all or a portion of our business or assets, we will disclose necessary Personal Data and such information will be governed by the privacy policies of acquiring entities. In accordance with applicable laws, we will use reasonable efforts to notify you of any transfer of Personal Data to an unaffiliated third party.
            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
            <strong>Data Analytics:</strong> <br/> Occasionally, we may share non-personal, anonymized, and statistical data with third parties for the purpose of helping CASHONSPOT with such analysis and improvements. CASHONSPOT may also use anonymous, aggregated utilization data for typical business operations (for example, Governance reporting).
            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
            <strong>Public Forums:</strong> <br/>If any part of the Website or CASHONSPOT branded social media sites permit public forum posting, the Personal Data you choose to post, share, upload, or make publicly available may be visible to others. You should never post or share any information that is confidential or about others unless you have permission to do so. We may use information you provide to personalize your experience and to make content recommendations.
            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
            <strong>Friends/Acquaintances: </strong> <br/> Please bear in mind that Cashonspot cannot control what your friends or acquaintances do with the information you share with them. Once displayed on publicly viewable web pages, information that you share can be collected and used by others. To request removal of your personal information from our blog or community forum, please contact us at sellatcashonspot.in@gmail.com.
            In some cases, we may not be able to remove your personal information, in which case we will let you know if we are unable to do so and why. We may also provide a feature allowing you to invite/refer a friend to join Cashonspot or avail our services. If you provide a friend’s email address to us for this reason, we will use that email address only for the purpose of sending a onetime invitation to your friend. Your friend may contact us at sellatcashonspot.in@gmail.com. to remove their information from our database.
            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
            <strong>Using your Data in accordance with Data Protection Law
            :</strong> 
            <br/>
            Data Protection Law requires that we meet certain conditions before we are allowed to use your personal data in the manner described in this privacy notice. To use your personal data, we will rely on one of the following conditions, depending on the activities we are carrying out:
              </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
            <strong>Consent:</strong> <br/>We may provide you with promotional offers related to our services or products where you have indicated your consent for us to do so (to the extent that we are required to collect consent under Data Protection Laws). We may contact you by mail or email, phone and electronic notifications (where you have agreed to those methods of communication) to provide you with the information on your requested service or product. You may change your marketing preferences at any time by contacting us as set out below.           </Typography>
          </li>

          <li className={classes.listItem}>
            <Typography variant="body1">
            <strong>Legitimate interests
            :</strong> 
            <br/>
            It is in our legitimate interests to collect your personal data as it provides us with information that we need to provide our services to you and to make our Platform available. This requires us to carry out a balancing test of our interests in using your personal data (for example, in order to provide you with access to the Platform and ensure no fraudulent activity is promoted through our platform), against the interests you have as a citizen and the rights you have under Data Protection Law (for example, to not have your personal data sold to third party marketing companies without your knowledge). The outcome of this balancing test will determine whether we may use your personal data in the ways described in this privacy notice. We will always act reasonably and give full and proper consideration to your interests in carrying out this balancing test.
To provide you with the services that we have agreed to provide to you we are permitted to hold and process some of your personal data because it is necessary to do so in order to provide you the access to, and to enable you to make use of, the platform. Without this personal data, we could not provide you with access to the Platform.
            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
            <strong>
            Compliance with a legal obligation

            :</strong> 
            <br/>
            We are permitted to process your personal data where it is necessary for compliance with our legal obligations. For legal claims, we are permitted to process your personal data where it is necessary to establish, pursue or defend a legal claim.</Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
            <strong>Substantial Public Interest

            :</strong> 
            <br/>
            We are permitted to process your personal data where it is necessary for reasons of substantial public interest, on the basis of Data
            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
            <strong>Protection Laws

            :</strong> 
            <br/>
            If we look to use your personal data for any other purpose not covered in this privacy notice, we will let you know about any proposed new purposes before using your personal data in this way.
            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
            <strong>Retention Period

            :</strong> 
            <br/>
            Our retention periods for the personal data are based on business needs and legal requirements. We retain personal data for as long as is necessary for the processing purpose(s) for which the information was collected, and any other permissible, related purpose.

            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
            <strong>
            We usually keep your information for as long as required to:

            :</strong> 
            <br/>
            – to respond to any questions or complaints;
– to show that we treated you fairly;
– to demonstrate compliance with our regulatory obligations; and/or
– to maintain records according to rules that apply to us.
We will also keep your information for as long as it is needed for legal, regulatory or technical reasons (such as where data has to be held in relation to historic insurance claims). We may also keep it for research or statistical purposes. If we do, we will make sure that your privacy is protected and only use it for those purposes. To the extent your current or potential employer has instructed us to process your personal data on their behalf, we will retain data in accordance with their instructions.

            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
            <strong>
            Location of Processing

            :</strong> 
            <br/>
            Cashonspot is solely located in, and operates from, India. Additionally, we host our Services and process your Personal Data solely in India. However, it is possible that some of the service providers referenced above may process Personal Data outside your jurisdiction, and in countries that are not subject to an adequacy decision by the European. Commission or your local legislature and/or regulator, and that may not provide for the same level of data protection as your jurisdiction. We ensure that the information continues to be protected.

            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
            <strong>Minors
            :</strong> 
            <br/>
            CASHONSPOT’s Services are solely intended for business clients and are not directed at minors. Transaction on the Platform is available only to persons who can form legally binding contracts under Indian Contract Act, 1872. Persons who are “incompetent to contract” within the meaning of the Indian Contract Act, 1872 including un-discharged insolvents etc. are not eligible to use the Platform. If you are a minor i.e. under the age of 18 years, you may use the Platform or access content on the Platform only under the supervision and prior consent/ permission of a parent or legal guardian. We do not knowingly collect Personal Data from children under the age of 18. If you are a parent or guardian and believe your child has provided us with Personal Data without your consent, please contact us as set forth below, and we will take steps to delete such Personal Data from our systems.

            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
            <strong>Security of personal information

            :</strong> 
            <br/>
            All information you provide to us is stored on our or our subcontractors’ secure servers and accessed and used subject to our security policies and standards. We have provided OTP based access to your account. You should keep this OTP confidential and not share it with anyone else including anyone who works for us. We restrict access to your personal data to those employees of ours, our affiliates, and third-party service providers who reasonably need it to provide products or services. We have implemented commercially reasonable physical, electronic, procedural, administrative, and technical safeguards in a way that complies with the security requirements of data protection laws to protect your personal data, located in the countries where we are based from any unauthorized access. However, as effective as our security measures are, no security system is impenetrable. We cannot guarantee the security of these systems, nor can we guarantee that information supplied by you or on your behalf cannot be intercepted while being transmitted over the Internet.
 

            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
            <strong>
            How we may contact you
            Service communications:
            :</strong> 
            <br/>
            We communicate with you regarding the Transaction that you have initiated with us. Marketing communications and editorial newsletters: If we have your permission, we may send you materials we think may interest you, such as Cashonspot new offers and updates. Depending on your marketing preferences, this may be by email or SMS.
You can decide not to receive these emails at any time, and you may manage your receipt of the same by clicking on the “unsubscribe” or other equivalent link located on the bottom of any emails, or contact us at sellatcashonspot.in@gmail.com

            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
            <strong>Customer Feedback:
            :</strong> 
            <br/>
            Sometimes we may contact you for feedback related to your transaction/experience with Cashonspot.

            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
            <strong>Responding to your queries or complaints: 
            :</strong> 
            <br/>
            If you have raised a query or a complaint with us, we may contact you to answer your query or to resolve your complaint.

            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
            <strong>
            Rights and Obligations with respect to your Data

            :</strong> 
            <br/>
            You have a number of rights under data protection laws in relation to the way we process your personal data. These are set out below. You may contact us using the details below to exercise any of these rights. We will respond to any request received from you within one month from the date of the request.0

            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
            <strong>
            DESCRIPTION OF RIGHTS

            :</strong> 
            <br/>
            <strong>Right 1 –:</strong> <br/>
             A right to access personal data held by us about you.<br/>

             <strong>Right 2 –:</strong><br/>
A right to require us to rectify any inaccurate personal data held by us about you.<br/>

<strong>Right 3 –:</strong><br/>
 A right to require us to erase personal data held by us about you. This right will only apply where (for example): we no longer need to use the personal data to achieve the purpose we collected it for; or where you withdraw your consent (if we are using your personal data based on your consent); or where you object to the way we process your personal data (in line with Right 6 below).<br/>
 <strong>Right 4 –:</strong><br/>
 A right to restrict our processing of personal data held by us about you. This right will only apply where (for example): you dispute the accuracy of the personal data held by us; or where you would have the right to require us to erase the personal data but would prefer that our processing is restricted instead; or where we no longer need to use the personal data to achieve the purpose we collected it for, but you require the data for the purposes of dealing with legal claims.<br/>
 <strong>Right 5 –:</strong><br/>
 – A right to receive personal data, which you have provided to us, in a structured, commonly used and machine-readable format. You also have the right to require us to transfer this personal data to another organization at your request.<br/>
<strong>Right 6 –:</strong><br/>
– A right to object to our processing of your personal data (including for the purposes of sending promotional messages to you).<br/>
<strong>Right 7 –:</strong><br/>
 – A right to withdraw your consent, where we are relying on it to use your personal data (for example, to provide you with promotional messages about our services or products). If you withdraw your consent, we may not be able to provide certain products or services to you.<br/>
These rights are subject to certain exemptions to safeguard the public interest (e.g. the prevention or detection of crime) and our interests (e.g. the maintenance of legal privilege).<br/>

            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
            <strong>Updating information

            :</strong> 
            <br/>
            Keeping your information accurate and up to date is very important. Inaccurate or incomplete information could impair our ability to deliver relevant services to you. We will use reasonable endeavors to ensure that your personal data is accurate. In order to assist us with this, you should notify us of any changes to your personal data by updating your profile on the Platform or by contacting us. Intentionally providing false or misleading information or using another person’s email address or personal data for the purposes of falsely obtaining any products or services through the Platform, may lead to termination of access to the Platform and may result in legal action.
            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
            <strong>Modifications to Privacy Notice

            :</strong> 
            <br/>
            We may change the content of the Platform and how we use cookies and consequently this privacy notice and our cookies notice and any other document to which they refer are subject to change at any time. If we make material updates to this privacy notice, we will update the date it was last changed and will clearly indicate in the document. Any changes we make to this privacy notice become effective immediately when we post the revised privacy notice on the Platform. We recommend that you review this privacy notice regularly for changes. This privacy notice was last updated on 10th October 2024.

            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
            <strong>Questions Regarding this Statement and Your Information

            :</strong> 
            <br/>
            If you have any questions or comments regarding this Statement or your information, please contact us at sellatcashonspot.in@gmail.com

            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
            <strong>Security of personal information

            :</strong> 
            <br/>
            We work dedicatedly to protect the security of your information during transmission by using the Secure Sockets Layer (SSL) software, which encrypts any information that you input. And the information is stored on our or our subcontractors’ secure servers and accessed and used subject to our security policies and standards. We have provided OTP based access to your account. You should keep this OTP confidential and not share it with anyone else including anyone who works for us. We restrict access to your personal data to those employees of ours, our affiliates, and third-party service providers who reasonably need it to provide products or services. We have implemented commercially reasonable physical, electronic, procedural, administrative, and technical safeguards in a way that complies with the security requirements of data protection laws to protect your personal data, located in the countries where we are based from any unauthorized access. However, as effective as our security measures are, no security system is impenetrable. We cannot guarantee the security of these systems, nor can we guarantee that information supplied by you or on your behalf cannot be intercepted while being transmitted over the Internet.

            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
            <strong>Conditions of Use & Revisions

            :</strong> 
            <br/>
            If you choose to visit our app/website/M-site, your visit & any dispute over privacy is subject to this policy, including limitations on damages, arbitration of disputes & application of the law of the Republic of India. If you have any concerns about privacy, please share a detailed account with us, on the mentioned email id & we will do our best to resolve it: Our business will evolve constantly. So will our policies & terms of membership. The use of information that we gather is subject to the privacy policy in effect at the time of use.

            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography variant="body1">
            <strong>How to complain
            :</strong> 
            <br/>
            Please let us know if you are unhappy with how we have used your personal data or are not satisfied with our handling of any request by you in relation to your rights. You can contact us by writing to us at: sellatcashonspot.in@gmail.com 
            </Typography>
          </li>

        </ul>
      </Box>
</Container>
  );
};

export default TermsAndConditions;
