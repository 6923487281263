import React,{useState,useRef} from 'react';
import { useLocation, useNavigate,useParams} from "react-router-dom";

import {Typography, Paper, Grid, Button, IconButton,Box,Dialog, DialogTitle, DialogContent, DialogActions,useMediaQuery } from '@mui/material';
 import Pickup from './Pickup'
import CancelOrderDialog from './CancelReason.js';
import Tick from "../../assets/images/Tick.jpeg";
import CloseIcon from '@mui/icons-material/Close';
import DeviceEvaluation from '../commonForms/DeviceEvaluation.js';
import axios from 'axios';
import "../../assets/css/confirmation.css"
import CancelIcon from '@mui/icons-material/Cancel';
import ReplayIcon from '@mui/icons-material/Replay';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportIcon from '@mui/icons-material/Report';
import { config } from '../../config';
import Footer from '../commonForms/Footer';
import FAQ from '../commonForms/FAQ'; 
import { useDispatch, useSelector } from 'react-redux';
import { handleCancelDialog } from '../../redux/WrapperSlice.js';
  import ArrowBackIcon from '@mui/icons-material/ArrowBack';
 
const ConfirmationPage = () => {
  const location = useLocation();
  const {
    PickupDetails,
    deviceAnswers,
    
  } = location.state || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productType,brand,model,variant,step1,step2,step3,step4,step5,step6,step7,step8,step9,step10} = useParams();
  let currentValue = useSelector((state) => state.wrapper.currentValue);
  const answers =  useSelector((state) => state.wrapper.answers);
  const formattedVariant = useSelector((state) => state.wrapper.formattedVariant);
  const FixedPrice = useSelector((state) => state.wrapper.FixedPrice);
  const city = useSelector((state) => state.wrapper.city);
  const rowDetails = useSelector((state) => state.wrapper.rowDetails);
  const warrantyStatus = useSelector((state) => state.wrapper.warrantyStatus);
  const selectedWarranty = useSelector((state) => state.wrapper.selectedWarranty);
  const selectedModel = useSelector((state) => state.wrapper.selectedModel);
  const DefectsselectedOptions = useSelector((state) => state.wrapper.DefectsselectedOptions);
  const ScreenselectedOptions = useSelector((state) => state.wrapper.ScreenselectedOptions);
  const functionalSelectedOptions = useSelector((state) => state.wrapper.functionalSelectedOptions);
  const accSelectedOptions =  useSelector((state) => state.wrapper.accSelectedOptions);
  const uniqueIdRef = useRef(location.state?.uniqueId1);
  const [currentPage, setCurrentPage] = useState('Confirmation');
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [reportDialogOpen, setReportDialogOpen] = useState(false); // State for report dialog
  const[OrderCancelled,setOrderCancelled] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const cancelDialog = useSelector((state) => state.wrapper.cancelDialog);

 
  const handleReschedule = () => {
    setCurrentPage("Pickup");
  }
  const handleCancelClick = () => {
    setCancelDialogOpen(true);
  };

  const handleCancelClose = () => {
    setCancelDialogOpen(false);
    dispatch(handleCancelDialog(false));
    if(uniqueIdRef.current){
      navigate("/profile");
     
     }
  };
  const handleCancelConfirm = async (reason) => {
    //console.log(uniqueIdRef,"uniqueIdRef")
    const userType = 1;
    try {
      const uniqueId = PickupDetails?.uniqueId ? PickupDetails?.uniqueId : uniqueIdRef.current; // Ensure this value is accessible here
      const status = 'Failed'; // Define the status as needed
      
      const response = await axios.post(`${config.Api}update-status/${uniqueId}`, { status, reason,userType});
  
      if (response.status === 200) {
       alert("Order cancelled successfully");
       setOrderCancelled(true);
       dispatch(handleCancelDialog(false));
       if(uniqueIdRef.current){
        navigate("/profile");
 
       }
      } else {
        alert("Failed to cancel order");
        setOrderCancelled(false);

        // You can add any additional logic here if needed, like showing an error message to the user
      }
    } catch (error) {
      // console.error("Error cancelling order:", error);
      // Handle error appropriately, maybe show an error message to the user
    }
  
    setCancelDialogOpen(false);
  };
  if(currentPage === "Pickup"){
    return <Pickup 
uniqueId={PickupDetails?.uniqueId}
modifyPickupDetails={PickupDetails}
deviceAnswers={deviceAnswers}
                 />
  }

  const handleReportOpen = () => {
    setReportDialogOpen(true); 
  };

  const handleReportClose = () => {
    setReportDialogOpen(false);
  };

  return (
    <>
    {currentPage === "Confirmation" && (
        <div>
          
          <Paper elevation={3} className="confirmation-paper">
          <IconButton style={{display:"flex"}} onClick={() => navigate("/")} className="confirmatonback">
                     <Button className='go-back-button' startIcon={<ArrowBackIcon className='go-back-icon'/>}>
                      <Typography variant="body1" className='go-back-text' sx={{ fontWeight: 'bold' }} color="#4BCEBD">
                             Back to Home
                            </Typography>
                     </Button> 
                    </IconButton>
          <Box mb={3} className="confirmation-icon-box">
          {!OrderCancelled ? 
                <img src={Tick} alt="Success Icon" className="confirmation-icon" />
                : 
          
                <CloseIcon className="confirmation-icon error-icon" />
             }
              
            </Box>
            <Typography variant="h4" gutterBottom className={OrderCancelled ? "cancel-order" : "confirm-order"}>
              {!OrderCancelled ? "Thank You!" : "Oops!"}
            </Typography>
            <Typography variant="body1" gutterBottom className={OrderCancelled ? "cancel-order" : "confirm-order"}>
              {!OrderCancelled ? "Your order has been confirmed" : "Your order has been cancelled"}
            </Typography>
            <Typography variant="body2" gutterBottom>
              Service Number: <strong>{PickupDetails?.uniqueId}</strong>
            </Typography>
            <Paper elevation={1} className="device-details-paper">
              <Typography variant="h6" gutterBottom>
                Device Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                <img
                    src={rowDetails && rowDetails[" Image URL"] || ""}
                    alt="Device"
                    className="device-image"
                  />
                </Grid>
                <Grid item xs={12} sm={8} className="device-info">
                  <Box mb={1} display="flex" justifyContent="space-between">
                  <Typography variant="body1" className="device-name">
                  {PickupDetails?.BrandName} {PickupDetails?.Model} ({PickupDetails?.formattedVariant})
                    </Typography>
                    <Button    startIcon={<CheckCircleIcon />}  // Added icon
 variant="outlined" size="small" className="pickup-request-button">
                      Pickup Requested
                    </Button>
                  </Box>
                  <Typography variant="body2">
                    Order created on: <strong>{new Date(PickupDetails?.createdAt).toLocaleDateString()}</strong>
                  </Typography>
                  <Typography variant="h2" sx={{
                    mb: 1,
                    fontWeight: 'bold',
                     color:'red',
                    //  fontSize:"large",
                     fontSize: '2rem',
                  }}>
                    Quote Price: <strong>₹{currentValue ? `${new Intl.NumberFormat('en-IN').format(currentValue)}` : ""}</strong>
                    {/* new Intl.NumberFormat('en-IN').format(maxValue) */}
                  </Typography>
                  <Typography variant="body2">
                  <a href="#view-device-report" onClick={handleReportOpen} className="view-report-link"><ReportIcon   fontSize="small" style={{ marginRight: '4px' }} />
                  View Device Report</a>
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            <Paper elevation={1} className="order-details-paper">
              <Typography variant="h6" gutterBottom>
                Order Details
              </Typography>
              <Box mb={2}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body2">Device</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" align="right">
                      {PickupDetails?.BrandName} {PickupDetails?.Model} ({PickupDetails?.formattedVariant})
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">Order Date</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" align="right">
                      {new Date(PickupDetails?.createdAt).toLocaleDateString()}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">Address</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" align="right"   sx={{
    wordWrap: 'break-word', // Break long words to wrap to the next line
    whiteSpace: 'normal',   // Allow text to wrap
    overflowWrap: 'break-word', // Handle long unbroken text
  }}>
                      {PickupDetails?.address}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">Schedule Date & Time</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" align="right">
                    {new Date(PickupDetails?.pickupDate).toLocaleDateString()} ({PickupDetails?.pickupTimeOption})
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Grid container spacing={2} style={{display:"none"}}>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                <Button 
                style={{fontSize:"12px"}}
                startIcon={<CancelIcon />}  // Added icon
disabled={OrderCancelled ? true : false} variant="outlined"   color="error" onClick={handleCancelClick} className={OrderCancelled ? "disable-cancel-order-button" : "cancel-order-button"}>
                Cancel Order
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                <Button  
                  style={{fontSize:"12px"}}   
                startIcon={<ReplayIcon />}  // Added icon
 variant="contained"   color="primary" onClick={handleReschedule} disabled={OrderCancelled ? true : false} className={OrderCancelled ? "disable-cancel-order-button" :"reschedule-button"  }>
                Reschedule
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  
                </Grid>
              </Grid>
            </Paper>
          </Paper>
          <CancelOrderDialog open={cancelDialogOpen || cancelDialog} onClose={handleCancelClose} onConfirm={handleCancelConfirm} />

          <Dialog open={reportDialogOpen} onClose={handleReportClose} maxWidth="sm" fullWidth>
            <DialogTitle>Device Evaluation</DialogTitle>
            <DialogContent>
              <DeviceEvaluation 
              // selectedModel={selectedModel}
              //  deviceAnswers={deviceAnswers}
              //   formattedVariant={formattedVariant}
              //    answers={answers} 
                 />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleReportClose} color="primary">Close</Button>
            </DialogActions>
          </Dialog>


          {!isMobile && (
      <>
    <FAQ  />
<Footer />
</>
)}

          </div>

          
      )}
    
    </>
    
  );
};

export default ConfirmationPage;
